import {ScrollRestoration} from "react-router-dom";
import {HelmetProvider} from "react-helmet-async";
import Router from "./routes";
import ThemeProvider from "./theme";
import CircularLoader from "./loader/circular/CircularLoader";
import {getUI} from "./features/ui/uiState";
import {useAppSelector} from "./hooks/hooks";
import {SocketContext, socket} from './components/socket-handler/sockethandler';
import {useEffect, useState} from "react";

export default function App() {
  const ui = useAppSelector(getUI);
  const [isConnected, setIsConnected] = useState(false);
  useEffect(() => {
    function onConnect() {
      setIsConnected(true);
    }

    function onDisconnect() {
      setIsConnected(false);
    }

    socket.on('connect', onConnect);
    socket.on('disconnect', onDisconnect);
    return () => {
      socket.off('connect', onConnect);
      socket.off('disconnect', onDisconnect);
    };
  }, []);
  return (
    <SocketContext.Provider value={socket}>
      <HelmetProvider>
        {ui?.awaitingApis ? <CircularLoader/> : <></>}
        <ThemeProvider>
          <Router>
            <ScrollRestoration/>
          </Router>
        </ThemeProvider>
      </HelmetProvider>
    </SocketContext.Provider>
  );
}
