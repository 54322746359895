/* eslint-disable */
import ReactDOM from "react-dom/client";
import { configureStore, ThunkAction, Action } from "@reduxjs/toolkit";

import storage from "redux-persist/lib/storage";
import { Provider } from "react-redux";
import { createMigrate, persistReducer, persistStore } from "redux-persist";
// import { legacy_createStore as createStore, applyMiddleware } from 'redux';
import thunk from "redux-thunk";

//
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import reportWebVitals from "./reportWebVitals";

import DataFetcher from "./dataFetcher/dataFetcher";
import rootReducer from "./reducer/rootReducer";
import "./scss/style.scss";
// ----------------------------------------------------------------------

const root = ReactDOM.createRoot(document.getElementById("root"));

// const store = createStore(
//   rootReducer,
//   applyMiddleware(thunk),
// );

const migrations = {
  0: (state) => {
    return {
      _persist: state._persist,
    };
  },
  // [config.reduxVersion]: (state) => {
  //   return {
  //     _persist: state._persist,
  //     ...reducers,
  //   };
  // },
};

const persistConfig = {
  key: "root",
  // # only required to change version if updating the existing reducers.
  // version: config.redux.persist.version,
  storage,
  blacklist: [
    "ui",
    //  "signin"
  ],
  migrate: createMigrate(migrations, { debug: true }),
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
  reducer: persistedReducer,
  middleware: [thunk],
  devTools: process.env.NODE_ENV !== "production",
});

if (process.env.NODE_ENV === 'production') {
  console.error = () => {}; // Suppress error logs
  console.warn = () => {};  // Suppress warning logs
  console.log = () => {};   // Suppress general logs (optional)
}

root.render(
  <Provider store={store}>
    <DataFetcher />
    <App />
  </Provider>
);

export const persistor = persistStore(store, {}, function () {
  persistor.persist();
});
// If you want to enable client cache, register instead.
serviceWorker.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
