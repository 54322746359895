import { useEffect, useState } from "react";
import {
  Stack,
  Checkbox,
  Box,
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  IconButton,
  Typography,
} from "@mui/material";
import getData, { getDataNoEnc } from "src/dataFetcher/getDataFetcher";
import { getUserInfo } from "src/features/user/userState";
import { useAppSelector } from "src/hooks/hooks";
import { backendBaseUrl } from "src/utils/backendUrls";
import useCustomerOrderInfo from "src/features/return_management/hooks/useCustomerOrderInfo";
import NumberInputBasic from "src/components/number-input/NumberInputBasic";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";

const ReturnItemCard = ({
  data,
  max_quantity,
  currency_symbol = "$",
  addItemToCheckout,
  deleteItemToCheckout,
  cbReasonCommentUpdate,
}) => {
  const { tenantInfo, brandPortalConfig } = useCustomerOrderInfo();
  const [showReturnFields, setShowReturnFields] = useState(false);
  const userInfo = useAppSelector(getUserInfo);
  const [selectValue, setSelectValue] = useState("");
  const [itemComments, setItemComments] = useState("");
  const [reasons, setReasons] = useState([]);
  const [quantity, setQuantity] = useState(max_quantity);

  const handleSelectChange = (event) => {
    setSelectValue(event.target.value);
    cbIemReasonCommentChange(event.target.value, quantity, itemComments);
  };
  const handleQuantityUpdate = (new_quantity) => {
    if (new_quantity <= max_quantity && new_quantity >= 1) {
      setQuantity(new_quantity);
      cbIemReasonCommentChange(selectValue, new_quantity, itemComments);
    }
  };
  const handleCommentChange = (event) => {
    setItemComments(event.target.value);
    cbIemReasonCommentChange(selectValue, quantity, event.target.value);
  };

  function cbIemReasonCommentChange(reason, qty, comments) {
    cbReasonCommentUpdate({
      line_item_id: data.id,
      reason: reason,
      quantity: qty,
      comments: comments,
    });
  }

  const handleCheckboxClicked = (event) => {
    if (event.target.checked) {
      setShowReturnFields(true);
      if (selectValue) addItemToCheckout(data);
    } else {
      setShowReturnFields(false);
      deleteItemToCheckout(data);
    }
  };
  useEffect(() => {
    async function fetchReasons() {
      const url = backendBaseUrl;
      let headers = { tenant_id: tenantInfo?.tenant_id };
      if (userInfo?.is_demo_user && userInfo.is_demo_user == true) {
        headers["is_demo_user"] = true;
      }
      const token = "";
      const result = await getData(
        `${url}tenant/${tenantInfo.tenant_name}/return/rules/info/return_rule`,
        {},
        headers,
        token
      );
      setReasons(result.data.return_reason);
    }

    fetchReasons();
  }, []);
  useEffect(() => {
    if (selectValue) {
      addItemToCheckout({
        ...data,
        quantity,
      });
    }
  }, [selectValue]);
  useEffect(() => {
    if (selectValue) {
      addItemToCheckout({
        ...data,
        quantity,
      });
    }
  }, [quantity]);

  return (
    <Stack
      direction={"row"}
      justifyContent={"space-between"}
      paddingY={3}
      paddingLeft={2}
      paddingRight={3}
      boxShadow={"0px 9px 30px 0px #00000014"}
      borderRadius={"10px"}
      backgroundColor="white"
    >
      <Stack direction={"row"} flex={1} alignItems={"center"}>
        {/* checkbox */}
        <Box>
          {data?.eligibleForReturn ? (
            <Checkbox onChange={handleCheckboxClicked} />
          ) : (
            <Box sx={{ px: "20px" }} />
          )}
        </Box>

        <Stack direction={"row"} gap={2} paddingY={1}>
          <Box>
            <img
              loading="lazy"
              src={data.imgUrl}
              style={{ maxWidth: "95px" }}
              width={130}
              height={"auto"}
            />
            {max_quantity > 1 && data?.eligibleForReturn && (
              <Box sx={{ display: "flex", gap: 1, alignItems: "center" }}>
                <IconButton
                  disabled={quantity <= 1}
                  onClick={() => handleQuantityUpdate(quantity - 1)}
                  sx={{
                    backgroundColor: `${brandPortalConfig?.color_theme?.brand_color}`,
                    "&:hover": {
                      backgroundColor: `${brandPortalConfig?.color_theme?.brand_color}`,
                    },
                    color: "white",
                    borderRadius: "4px",
                  }}
                  size="small"
                  aria-label="delete"
                >
                  <RemoveIcon />
                </IconButton>
                <Box sx={{ position: "relative" }}>
                  <TextField
                    sx={{
                      width: "40px",
                      "& .MuiInputBase-input": { padding: "6px" },
                    }}
                    value={quantity}
                    size="small"
                  />
                  <Typography
                    sx={{ position: "absolute", top: "5.5px", left: "15px" }}
                  >
                    /{max_quantity}
                  </Typography>
                </Box>
                <IconButton
                  disabled={max_quantity == quantity}
                  onClick={() => handleQuantityUpdate(quantity + 1)}
                  sx={{
                    backgroundColor: `${brandPortalConfig?.color_theme?.brand_color}`,
                    "&:hover": {
                      backgroundColor: `${brandPortalConfig?.color_theme?.brand_color}`,
                    },
                    color: "white",
                    borderRadius: "4px",
                  }}
                  size="small"
                  aria-label="delete"
                >
                  <AddIcon />
                </IconButton>
              </Box>
            )}
          </Box>
          <Stack justifyContent={"space-between"} paddingY={1.3}>
            <h3
              style={{
                fontSize: 20,
              }}
            >
              {data.title}
            </h3>
            <Stack
              direction={"row"}
              gap={2}
              color={"#AAAAAA"}
              paddingBottom={1}
            >
              <p>{data.description}</p>
              <p>/</p>
              <p>Qty {max_quantity}</p>
            </Stack>

            <p>
              {currency_symbol} {data.price}
            </p>
          </Stack>
        </Stack>
      </Stack>
      <Stack justifyContent={"center"}>
        {!data?.eligibleForReturn ? (
          <Stack
            direction={"row"}
            alignItems={"center"}
            gap={1.2}
            padding={"4px 12px"}
            bgcolor={"#EA191933"}
            color={"#EA1919"}
            borderRadius={"20px"}
            fontSize={14}
            margin={"auto"}
          >
            <p>Not Eligible for Return</p>
          </Stack>
        ) : (
          showReturnFields && (
            <Stack
              gap={2}
              sx={{
                width: "100%",
              }}
            >
              <FormControl fullWidth>
                <InputLabel id="select-label">
                  Select a return reason
                </InputLabel>
                <Select
                  labelId="select-label"
                  id="select"
                  label="Select a return reason"
                  value={selectValue}
                  onChange={handleSelectChange}
                  sx={{
                    color: "common.black",
                    width: "300px",
                  }}
                  MenuProps={{
                    PaperProps: {
                      style: {
                        maxHeight: 160,
                      },
                    },
                  }}
                >
                  {reasons?.map((el) => (
                    <MenuItem
                      value={el}
                      sx={{
                        ":hover": { backgroundColor: "#E9E9E9" },
                      }}
                    >
                      {el}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <TextField
                multiline
                onChange={handleCommentChange}
                label="Add your additional comment (Optional)"
                maxRows={1}
              />
            </Stack>
          )

        )}
      </Stack>
    </Stack>
  );
};
export default ReturnItemCard;
