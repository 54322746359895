/* eslint-disable */
import {
  Navigate,
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";

// layouts
import DashboardLayout from "./layouts/dashboard";
import {useState, useEffect, useMemo} from "react";

//
import MailScans from "./pages/MailScans";
import TrackingPage from "./pages/TrackingPage";
//import LoginPage from "./pages/LoginPage";
import ShopifyAuthorize from "./pages/ShopifyAuthorize";
import Page404 from "./pages/Page404";
import ConfigPage from "./pages/ConfigPage";
import Reports from "./pages/Reports/Reports";
import DashboardAppPage from "./pages/DashboardAppPage";
import SigninFormContainer from "./sections/auth/signin/formsContainer/formsContainer";
import Carrier from "./sections/auth/signin/formsContainer/carrier/carrier";
import ManageForm from "./sections/auth/signin/formsContainer/manage/manage";
import SigninCarrier from "./sections/auth/signin/formsContainer/carrier/signinCarrier/signinCarrier";
import SigninManage from "./sections/auth/signin/formsContainer/manage/signinManage/signinManage";
import Plans from "./sections/auth/signin/formsContainer/plans/plans";
import Billing from "./sections/auth/signin/formsContainer/billing/billing";
import Checkout from "./sections/auth/signin/formsContainer/checkout/checkout";
import WelcomeView from "./sections/auth/signin/welcome/welcome";
import {connect, useDispatch} from "react-redux";
import {PropTypes} from "prop-types";
import {getToken} from "./utils/extractUserToken";
import {logoutUser} from "./features/user/userState";
import {resetScanState} from "./features/scan/scanState";
import {resetConfigState} from "./features/config/configState";
import {resetTrackState} from "./features/tracking/trakingState";
import {decrementAwaitingApiCounter} from "./features/ui/uiState";
import {setSigninData} from "src/features/signin/signin.action";
import {getUserInfo} from "src/features/user/userState";
import {useAppSelector} from "src/hooks/hooks";
import AverageTimeTracker from "./pages/Reports/AverageTimeTracker";
import ResetPasswordStep1Page from "./pages/ResetPassword/ResetPasswordStep1Page";
import ResetPasswordStep2Page from "./pages/ResetPassword/ResetPasswordStep2Page";
import ResetPasswordStep3Page from "./pages/ResetPassword/ResetPasswordStep3Page";
import ResetPasswordLogged from "./pages/ResetPassword/ResetPasswordLogged";
import ResetPasswordDone from "./pages/ResetPassword/ResetPasswordDone";
import UsersPage from "./pages/Users/UsersPage";
import BillingInvoice from "./pages/billing/billing";
import isAdminUser from "./utils/isAdminUser";
import UpgradePlan from "./pages/UpgradePlan/UpgradePlan";
import DemoOrderCompleted from "./sections/auth/signin/demoOrderCompleted/DemoOrderCompleted";
import {getPlansInfo, getPlansThunk} from "./features/plans/plansState";
import planNames from "./utils/planNames";
import NotificationsPage from "./pages/Notifications/NotificationsPage";
import appUrls from "./utils/appUrls";

import BrandedPortal from "./pages/BrandedPortal/BrandedPortal";
import Returns from "./pages/returns/Returns";
import Portal from "./pages/portal";
import ReturnRules from "./pages/ReturnConfiguration/ReturnRules";
import ReturnsDetail from "./pages/returns/ReturnsDetail";
import ReturnsLogin from "./pages/portal/guestFlow/ReturnsLogin";
import ReturnsHome from "./pages/portal/guestFlow/ReturnsHome";
import TrackingItems from "./pages/portal/guestFlow/TrackingItems";
import ReturnsTracking from "./pages/portal/guestFlow/ReturnTracking";
import ReturnsLoggedUser from "./pages/portal/guestFlow/ReturnsLoggedUser";
import DummyReturn from "./pages/portal/guestFlow/DummyReturn";
import ReturnConfiguration from "./pages/ReturnConfiguration/ReturnConfiguration";
import Preview from "./pages/BrandedPortal/Preview";
import ReturnsBrandPortal from "./pages/ReturnsBrandPortal/ReturnsBrandPortal";
import CreateReturnDummy from "./pages/CreateReturn";

function Router({user, ui}) {
  const dispatch = useDispatch();
  const userInfo = useAppSelector(getUserInfo);
  const [token, setToken] = useState(getToken());
  const location = window.location.pathname;

  useEffect(() => {
    const fetchedToken = getToken();
    if (!fetchedToken) {
      const isCurrentRoute = location === '/shopify/authorize'
      if (!isCurrentRoute) {
        setToken(false);
        dispatch(logoutUser());
        dispatch(resetScanState());
        dispatch(resetConfigState());
        dispatch(resetTrackState());
        dispatch(decrementAwaitingApiCounter());
      }
    } else {
      setToken(fetchedToken);
    }
  }, [token, user, ui]);

  useMemo(async () => {
    await dispatch(getPlansThunk());
  }, []);

  const plansInfo = useAppSelector(getPlansInfo);

  const isAdmin = isAdminUser(user?.userInfo);
  const isPlanExpired = user?.userInfo?.is_plan_expired;
  const planId = user?.userInfo?.plan_id;

  const isDemoExpired =
    isPlanExpired && planId === plansInfo[planNames.demo]?._id;
  const isFreeExpired =
    isPlanExpired && planId === plansInfo[planNames.free]?._id;

  const RedirectAccordingToPlan = () => {
    if (token) {
      if (isDemoExpired) return <Navigate to="/onboarding/carrier"/>;
      else if (isFreeExpired) return <Navigate to="/upgrade-plan"/>;
      else return <Navigate to={appUrls.outboundManagement.baseUrl}/>;
    } else return <RedirectToShopifyAdmin/>;
  };

  const RedirectToShopifyAdmin = () => {
    // Redirect to Shopify admin login
    window.location.href = 'https://admin.shopify.com';
    return null; // Optionally, return null to prevent rendering anything on the page
  };

  const RedirectAccordingToPlanShopify = () => {
    if (token) {
      if (isDemoExpired) return <Navigate to="/onboarding/carrier"/>;
      else if (isFreeExpired) return <Navigate to="/upgrade-plan"/>;
      else return <Navigate to={appUrls.outboundManagement.baseUrl}/>;
    } else return <ShopifyAuthorize/>;
  };

  const RedirectAccordingToPlanDashboard = () => {
    if (token) {
      if (isDemoExpired) {
        dispatch(
          setSigninData({
            name: userInfo?.first_name,
            lastName: userInfo?.last_name,
            email: userInfo?.email,
            phone: userInfo?.phone_number,
            shippingvolume: userInfo?.shipping_volume,
            company: userInfo?.company_name,
            user_id: userInfo?.user_id,
            continueBtn: true,
          })
        );

        return <Navigate to="/onboarding/carrier"/>;
      } else if (isFreeExpired) return <Navigate to="/upgrade-plan"/>;
      else return <DashboardLayout/>;
    } else return <RedirectToShopifyAdmin/>;
  };

  const ValidateUpgradePlanAccess = () => {
    if (token) {
      if (isFreeExpired) return <UpgradePlan/>;
      else return <Navigate to={appUrls.outboundManagement.baseUrl}/>;
    } else return <RedirectToShopifyAdmin/>;
  };

  const ValidateToken = () => {
    if (token) {
      if (isDemoExpired || planId === plansInfo[planNames.demo]?._id)
        return <SigninFormContainer/>;
      else if (isFreeExpired) return <UpgradePlan/>;
      else return <Navigate to={appUrls.outboundManagement.baseUrl}/>;
    } else return <SigninFormContainer/>;
  };

  const ValidateTokenOrder = () => {
    if (token) {
      if (isDemoExpired || planId === plansInfo[planNames.demo]?._id)
        return <DemoOrderCompleted/>;
      else return <Navigate to={appUrls.outboundManagement.baseUrl}/>;
    } else return <DemoOrderCompleted/>;
  };

  const routes = createBrowserRouter([
    {
      path: appUrls.login,
      element: <RedirectToShopifyAdmin/>
    },
    {
      path: appUrls.shopifyAuthorize,
      element: RedirectAccordingToPlanShopify(),
    },
    // On boarding routes
    {
      path: "onboarding",
      children: [
        {
          element: <SigninFormContainer/>,
          children: [
            {path: "carrier", element: <Carrier/>},
            {path: "signin-carrier", element: <SigninCarrier/>},
            {path: "plans", element: <Plans/>},
            {path: "billing", element: <Billing/>},
            {path: "checkout", element: <Checkout/>},
          ],
        },
      ],
    },
    // Delivery management routes
    {
      path: appUrls.outboundManagement.baseUrl,
      element: RedirectAccordingToPlanDashboard(),
      children: [
        {
          element: (
            <Navigate to={appUrls.outboundManagement.dashboard.absoluteUrl}/>
          ),
          index: true,
        },
        {
          path: appUrls.outboundManagement.dashboard.relativeUrl,
          element: <DashboardAppPage/>,
        },

        {
          path: appUrls.outboundManagement.tracking.relativeUrl,
          element: <TrackingPage/>,
        },
        {
          path:
            appUrls.outboundManagement.tracking.relativeUrl + ":trackingNumber",
          element: <TrackingPage/>,
        },
        {
          path:
            appUrls.outboundManagement.tracking.relativeUrl +
            "filter/:status/:dashboardStartDate/:dashboardEndDate",
          element: <TrackingPage/>,
        },
        {
          path:
            appUrls.outboundManagement.tracking.relativeUrl +
            "scan_filter/:first_scan/:dashboardStartDate/:dashboardEndDate",
          element: <TrackingPage/>,
        },

        {
          path: appUrls.outboundManagement.carrierOriginScan.relativeUrl,
          element: <MailScans/>,
        },
        {
          path: appUrls.outboundManagement.reports.baseUrl,
          element: <Reports/>,
        },
        {
          path:
            appUrls.outboundManagement.reports.baseUrl +
            appUrls.outboundManagement.reports.preShipment.relativeUrl,
          element: <Reports/>,
        },
        {
          path:
            appUrls.outboundManagement.reports.baseUrl +
            appUrls.outboundManagement.reports.inTransit.relativeUrl,
          element: <Reports/>,
        },
        {
          path:
            appUrls.outboundManagement.reports.baseUrl +
            appUrls.outboundManagement.reports.averageTimeInTransit.relativeUrl,
          element: <AverageTimeTracker/>,
        },
      ],
    },

    // Returns & Refunds
    {
      path: appUrls.inboundManagement.baseUrl,
      element: RedirectAccordingToPlanDashboard(),
      children: [
        {
          path: appUrls.inboundManagement.brandedReturnPortal.relativeUrl,
          element: <BrandedPortal/>,
        },
        {
          path: appUrls.inboundManagement.manageReturns.relativeUrl,
          element: <Returns/>,
        },
        {
          path: appUrls.inboundManagement.brandedReturnsPortal.relativeUrl,
          element: <ReturnsBrandPortal/>,
        },
        {
          path: appUrls.inboundManagement.returnDetails.relativeUrl,
          element: <ReturnsDetail/>,
        },
      ],
    },

    // Config management
    {
      path: appUrls.configurationManagement.baseUrl,
      element: RedirectAccordingToPlanDashboard(),
      children: [
        {
          path: appUrls.configurationManagement.deliveryTrackingConfiguration
            .relativeUrl,
          element: isAdmin ? (
            <ConfigPage/>
          ) : (
            <Navigate to={appUrls.outboundManagement.baseUrl}/>
          ),
        },
        {
          path: appUrls.configurationManagement.returnConfiguration.relativeUrl,
          element: <ReturnConfiguration/>,
        },
      ],
    },

    // General Routes
    {
      path: "/",
      element: RedirectAccordingToPlanDashboard(),
      children: [
        {
          path: "returns_support/:tenant_name/create-return",
          element: <ReturnsHome isHeader={false}/>,
        },
        {
          path: "returns_support/:tenant_name/tracking-items",
          element: <TrackingItems isHeader={false}/>,
        },
        {
          path: "returns_support/:tenant_name/tracking",
          element: <ReturnsTracking isHeader={false}/>,
        },
        {
          path: appUrls.users,
          element: isAdmin ? (
            <UsersPage/>
          ) : (
            <Navigate to={appUrls.outboundManagement.baseUrl}/>
          ),
        },
        {
          path: appUrls.notifications,
          element: <NotificationsPage/>,
        },
      ],
    },

    // TODO: make this routes protected
    {path: "portal", element: <Portal/>},
    {path: "returns/:tenant_name", element: <ReturnsLogin/>},

    {path: "returns/:tenant_name/demo", element: <Preview/>},
    {path: "returns/:tenant_name/portal", element: <ReturnsLoggedUser/>},
    {
      path: "returns/:tenant_name/tracking-items",
      element: <TrackingItems isReturnHeadColor={true}/>,
    },
    {path: "returns/:tenant_name/tracking-info", element: <TrackingItems/>},
    {
      path: "returns/:tenant_name/create-return",
      element: <ReturnsHome isReturnHeadColor={true}/>,
    },
    {path: "returns/:tenant_name/tracking", element: <ReturnsTracking/>},
    {path: "upgrade-plan", element: ValidateUpgradePlanAccess()},
    {path: "welcome", element: <WelcomeView/>},
    {path: "demo-order-completed", element: ValidateTokenOrder()},
    {path: "reset-password/step-1", element: <ResetPasswordStep1Page/>},
    {path: "reset-password/step-2", element: <ResetPasswordStep2Page/>},
    {path: "reset-password/step-3", element: <ResetPasswordStep3Page/>},
    {path: "reset-password/logged", element: <ResetPasswordLogged/>},
    {path: "reset-password/done", element: <ResetPasswordDone/>},
    {path: "createReturn", element: <DummyReturn/>},

    {
      children: [
        {
          element: (
            <Navigate to={appUrls.outboundManagement.dashboard.absoluteUrl}/>
          ),
          index: true,
        },
        {path: "*", element: <Page404/>},
      ],
    },
  ]);

  return <RouterProvider router={routes}/>;
}

Router.propTypes = {
  user: PropTypes.shape({}),
};
const mapDispatch = (state) => ({
  user: state.user,
  ui: state.ui,
});

export default connect(mapDispatch)(Router);
