import React, { useEffect, useState } from "react";
import {
  Stack,
  Checkbox,
  Box,
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Typography,
  Grid,
  Divider,
  ListItemText,
  Dialog,
  FormHelperText,
} from "@mui/material";
import TrackingItemCard from "./TrackingItemCard";
import useCustomerOrderInfo from "../../../features/return_management/hooks/useCustomerOrderInfo";
import {
  LoadingButton,
  Timeline,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  TimelineItem,
  TimelineOppositeContent,
  TimelineSeparator,
  timelineOppositeContentClasses,
} from "@mui/lab";
import {
  getTrackingInfoFromCarrier,
  getTrackingTimlineInfo,
} from "../PortalApi";

import moment from "moment";
import { unixToTime } from "src/utils/core";
import typography from "src/theme/typography";
import { updateTrackingDetails } from "src/features/returnTracking/returnTrackingApis";
import { getUserInfo } from "src/features/user/userState";
import { useAppSelector } from "src/hooks/hooks";
import { LABEL_PROCESS_TYPE } from "../../ReturnConfiguration/ReturnMethod/Labels";
import CircularLoader from "../../../loader/circular/CircularLoader";

const RefundEstimateInfo = ({
  data,
  currency_symbol,
  trackingItems,
  returnEstimate,
}) => {
  const {
    trackingPortalConfig,
    customerOrderInfo,
    tenantInfo,
    returnInfo,
    fetchReturnInformationById,
  } = useCustomerOrderInfo();

  const [updatedTrackingNumber, setUpdatedTrackingNumber] = useState(
    returnInfo?.label_info?.tracking_number
  );
  const [updatedCarrier, setUpdatedCarrier] = useState(
    returnInfo?.label_info?.carrier?.toUpperCase()
  );
  const [isSuccessModalOpen, setSuccessModalOpen] = useState(false);
  const [successModalText, setSuccessModalText] = useState("");
  const [isCustomLoading, setIsCustomLoading] = useState(false);
  const [timelineData, setTimelineData] = useState([]);
  const [carrier, setCarrier] = useState("");
  const [trackingNumber, setTrackingNumber] = useState("");
  const [trackingNumberFieldError, setTrackingNumberFieldError] =
    useState(false);

  function calculateGrandTotal(estimate) {
    const { shipping_maximum_refundable, tax_amount, return_items_amount } =
      estimate;
    const value = (
      Math.abs(shipping_maximum_refundable) +
      Math.abs(tax_amount) +
      Math.abs(return_items_amount)
    )?.toFixed(2);
    if (isNaN(value)) {
      return "--";
    }
    return value;
  }

  useEffect(() => {
    let trackNo =
      returnInfo?.label_info?.tracking_number || returnInfo?.tracking_no;
    let carrierCode = returnInfo?.label_info?.carrier || returnInfo?.carrier;
    let tenantId = returnInfo?.tenant_id;

    const fetchTrackingInfo = async () => {
      if (
        returnInfo?.label_type !== LABEL_PROCESS_TYPE.TWO_WAY_LABEL &&
        returnInfo?.label_info &&
        Object.keys(returnInfo.label_info).length > 0
      ) {
        trackNo = returnInfo.label_info?.tracking_number;
        carrierCode = returnInfo.label_info?.carrier;
        if (trackNo != "" && carrierCode != "") {
          const response = await getTrackingInfoFromCarrier(
            tenantId,
            carrierCode.toLowerCase(),
            trackNo
          );
          if (response?.data) {
            setTimelineData(response.data.events);
          }
        }
      } else {
        if (trackNo && carrierCode) {
          getTrackingTimlineInfo(trackingNumber, tenantInfo)
            .then((res) => {
              if (res.statusCode === 200) {
                setTimelineData(res.data.events);
              }
            })
            .catch((e) => console.log("new api err", e));
        }
      }
      setCarrier(carrierCode?.toString().toUpperCase());
      setTrackingNumber(trackNo);
    };
    fetchTrackingInfo();
  }, [returnInfo]);

  console.log("returnInfo", returnInfo);

  const unixToDate = (unixdata) => {
    const date = moment
      .unix(unixdata)
      .tz("America/Los_Angeles")
      .format("MMM DD");

    if (date === "Invalid date") return "--";
    return date;
  };

  const carrierimages = {
    UPS: "/assets/ups.png",
    USPS: "/assets/usps.png",
    FEDEX: "/assets/fedex.png",
    DHL: "/assets/dhl.png",
  };

  const userInfo = useAppSelector(getUserInfo);

  const handleUpdate = async () => {
    if (!updatedTrackingNumber) {
      setTrackingNumberFieldError("Tracking Number is required.");
      return;
    }

    const body = {
      tracking_number: updatedTrackingNumber,
      carrier: updatedCarrier,
    };
    setIsCustomLoading(true);
    try {
      const response = await updateTrackingDetails(
        userInfo,
        body,
        returnInfo?._id
      );
      fetchReturnInformationById(tenantInfo, {
        return_id: returnInfo?._id,
      });
      if (response?.statusCode === 200) {
        setSuccessModalText("Successfully Updated Your Tracking Details!");
      } else {
        setSuccessModalText(
          "There was an error updating your tracking details. Please try again."
        );
      }
      setIsCustomLoading(false);
      setSuccessModalOpen(true);
    } catch (error) {
      console.log("error", error);
    }
    setIsCustomLoading(false);
  };

  useEffect(() => {
    if (isSuccessModalOpen) setTrackingNumberFieldError("");
  }, [isSuccessModalOpen]);

  function getTrackingLink() {
    if (carrier == "USPS") {
      return `https://tools.usps.com/go/TrackConfirmAction_input?qtc_tLabels1=${trackingNumber}`;
    } else if (carrier == "FEDEX") {
      return `https://www.fedex.com/wtrk/track/?action=track&tracknumbers=${trackingNumber}&locale=en_us&cntry_code=us`;
    } else if (carrier == "UPS") {
      return `https://www.ups.com/track?HTMLVersion=5.0&Requester=NES&AgreeToTermsAndConditions=yes&loc=en_US&tracknum=${trackingNumber}`;
    } else if (carrier == "DHL") {
      return `https://www.dhl.com/us-en/home/tracking.html?tracking-id=${trackingNumber}`;
    }
    return "";
  }

  return (
    <Grid item xs={12} lg={4}>
      {isCustomLoading && <CircularLoader />}
      <Stack gap={2} mt={4}>
        <Typography fontFamily={typography.fontFamilySemiBold} fontSize={18}>
          {returnInfo.return_status === "Refunded"
            ? "Refund Summary "
            : "Estimated Refund "}
          (
          {trackingItems?.length > 1
            ? `${trackingItems?.length} items`
            : `${trackingItems?.length} item`}
          )
        </Typography>
        <Box
          sx={{
            borderRadius: `${trackingPortalConfig?.corner_radius}px`,
            backgroundColor: "white",
            boxShadow: "0px 9px 30px 0px #00000014",
          }}
        >
          <Box sx={{ padding: "20px" }}>
            {trackingItems.map((item) => (
              <Stack justifyContent={"space-between"} direction={"row"}>
                <Typography
                  sx={{
                    color: `${trackingPortalConfig?.color_theme?.secondary_color}`,
                  }}
                >
                  {item.title}
                </Typography>
                <Stack gap={0.3}>
                  <Typography
                    sx={{
                      color: `${trackingPortalConfig?.color_theme?.secondary_color}`,
                    }}
                  >
                    {currency_symbol}{" "}
                    {(Math.abs(item?.price) * item?.quantity)?.toFixed(2)}
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: "12px",
                      color: `${trackingPortalConfig?.color_theme?.secondary_color}`,
                      textAlign: "right",
                    }}
                  >
                    Qty: {item.quantity}
                  </Typography>
                </Stack>
              </Stack>
            ))}
          </Box>
          <Divider />
          <Stack gap={1} sx={{ padding: "20px" }}>
            <Box sx={{ display: "flex", justifyContent: "space-between" }}>
              <Typography
                sx={{
                  color: `${trackingPortalConfig?.color_theme?.secondary_color}`,
                }}
              >
                Refund Subtotal
              </Typography>
              <Typography
                sx={{
                  color: `${trackingPortalConfig?.color_theme?.secondary_color}`,
                }}
              >
                {currency_symbol}{" "}
                {returnEstimate?.return_items_amount?.toFixed(2)}
              </Typography>
            </Box>
            <Box sx={{ display: "flex", justifyContent: "space-between" }}>
              <Typography
                sx={{
                  color: `${trackingPortalConfig?.color_theme?.secondary_color}`,
                }}
              >
                Tax
              </Typography>
              <Typography
                sx={{
                  color: `${trackingPortalConfig?.color_theme?.secondary_color}`,
                }}
              >
                {currency_symbol} {returnEstimate?.tax_amount?.toFixed(2)}
              </Typography>
            </Box>
            <Box sx={{ display: "flex", justifyContent: "space-between" }}>
              <Typography
                sx={{
                  color: `${trackingPortalConfig?.color_theme?.secondary_color}`,
                }}
              >
                Shipping
              </Typography>
              <Typography
                sx={{
                  color: `${trackingPortalConfig?.color_theme?.secondary_color}`,
                }}
              >
                {currency_symbol}{" "}
                {returnEstimate?.shipping_maximum_refundable?.toFixed(2)}
              </Typography>
            </Box>
            {returnEstimate?.adjustment_amount > 0 && (
              <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                <Typography
                  sx={{
                    color: `${trackingPortalConfig?.color_theme?.secondary_color}`,
                  }}
                >
                  Deduction
                </Typography>
                <Typography
                  sx={{
                    color: `${trackingPortalConfig?.color_theme?.secondary_color}`,
                  }}
                >
                  (-) {currency_symbol}{" "}
                  {returnEstimate?.adjustment_amount?.toFixed(2)}
                </Typography>
              </Box>
            )}
          </Stack>
          <Divider />
          <Box
            sx={{
              padding: "20px",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Typography
              sx={{
                color: `${trackingPortalConfig?.color_theme?.brand_color}`,
              }}
            >
              {returnInfo.return_status === "Refunded"
                ? "Total Refund"
                : "Total Estimate Refund"}
            </Typography>
            <Typography
              sx={{
                color: `${trackingPortalConfig?.color_theme?.brand_color}`,
              }}
            >
              {currency_symbol}{" "}
              {returnInfo.return_status === "Refunded"
                ? Number(returnEstimate?.total_refund||returnEstimate?.return_items_amount)?.toFixed(2)
                : calculateGrandTotal(returnEstimate||returnEstimate?.return_items_amount)}
            </Typography>
          </Box>
        </Box>
      </Stack>
      {returnInfo?.return_status &&
        returnInfo?.return_status !== "Initiated" &&
        returnInfo?.return_status !== "Refunded" && (
          <Stack gap={2} mt={4}>
            <Typography
              fontFamily={typography.fontFamilySemiBold}
              fontSize={18}
            >
              Tracking details
            </Typography>
            <Box
              sx={{
                // padding: '20px',
                borderRadius: `${trackingPortalConfig?.corner_radius}px`,
                backgroundColor: "white",
                boxShadow: "0px 9px 30px 0px #00000014",
                height: "auto",
              }}
            >
              {carrier !== "" && carrier != null && trackingNumber !== "" && (
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    position: "sticky",
                    padding: "20px",
                    top: 0,
                    backgroundColor: "white",
                    zIndex: 1,
                  }}
                >
                  <img
                    src={carrierimages[carrier]}
                    height={40}
                    sx={{ width: "100%" }}
                  />
                  {trackingNumber != null && trackingNumber != "" && (
                    <Typography sx={{ fontSize: "12px" }}>
                      Tracking Number: {trackingNumber}
                    </Typography>
                  )}
                </Box>
              )}

              {timelineData?.length !== 0 ? (
                <Box>
                  <Timeline
                    sx={{
                      [`& .${timelineOppositeContentClasses.root}`]: {
                        flex: 0.2,
                        pl: 0,
                      },
                    }}
                    style={{
                      maxHeight: "300px",
                      overflowY: "auto",
                      scrollbarWidth: "thin", // Works in Firefox
                    }}
                  >
                    {timelineData?.map((item) => {
                      return (
                        <TimelineItem>
                          <TimelineOppositeContent
                            color="text.secondary"
                            sx={{ textAlign: "center", padding: "0px 16px" }}
                          >
                            <Typography
                              sx={{
                                fontSize: "12px",
                                color: "#000",
                                textWrap: "nowrap",
                              }}
                            >
                              {unixToTime(item.date)}
                            </Typography>
                            <Typography
                              sx={{ fontSize: "12px", color: "#AAAAAA" }}
                            >
                              {unixToDate(item.date)}
                            </Typography>
                          </TimelineOppositeContent>
                          <TimelineSeparator>
                            <TimelineDot
                              sx={{ backgroundColor: "black", margin: "2px 0" }}
                            />
                            <TimelineConnector
                              sx={{ backgroundColor: "black" }}
                            />
                          </TimelineSeparator>
                          <TimelineContent sx={{ padding: "0 16px" }}>
                            <Typography
                              sx={{ fontSize: "12px", color: "#000" }}
                            >
                              {item.status}
                            </Typography>
                            <Typography
                              sx={{ fontSize: "12px", color: "#AAAAAA" }}
                            >
                              {item.location.city}
                            </Typography>
                          </TimelineContent>
                        </TimelineItem>
                      );
                    })}
                  </Timeline>
                </Box>
              ) : returnInfo?.label_type === LABEL_PROCESS_TYPE.OWN_LABEL &&
                customerOrderInfo?.return_info?.return_status === "Accepted" ? (
                <Stack padding={"20px 60px"} alignItems={"center"} gap={3}>
                  <Typography>Please update your tracking here</Typography>
                  <Stack gap={2} width={"100%"}>
                    <Stack direction={"column"}>
                      <TextField
                        fullWidth
                        label="Enter your tracking number"
                        name="trackingNumber"
                        value={updatedTrackingNumber}
                        onChange={(e) =>
                          setUpdatedTrackingNumber(e.target.value)
                        }
                        error={!!trackingNumberFieldError}
                      />
                      {trackingNumberFieldError && (
                        <FormHelperText error={!!trackingNumberFieldError}>
                          {trackingNumberFieldError}
                        </FormHelperText>
                      )}
                    </Stack>
                    <FormControl fullWidth>
                      <InputLabel>Select your carrier</InputLabel>
                      <Select
                        label="Select your carrier"
                        value={updatedCarrier}
                        onChange={(e) => setUpdatedCarrier(e.target.value)}
                        sx={{
                          maxHeight: "56px",
                        }}
                      >
                        {["USPS", "UPS", "DHL", "FEDEX"].map((carrier) => (
                          <MenuItem key={carrier} value={carrier}>
                            <ListItemText primary={carrier} />
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Stack>
                  <LoadingButton
                    sx={{
                      backgroundColor: `${trackingPortalConfig?.color_theme?.brand_color}`,
                      color: "#fff",
                      paddingX: 2,
                      paddingY: 0.8,
                      "&:hover": {
                        backgroundColor: `${trackingPortalConfig?.color_theme?.brand_color}`,
                      },
                    }}
                    onClick={handleUpdate}
                  >
                    Update
                  </LoadingButton>
                </Stack>
              ) : (
                <></>
              )}
              {timelineData.length == 0 &&
                trackingNumber != "" &&
                trackingNumber != null && (
                  <Typography
                    sx={{
                      textAlign: "center",
                      color: `${trackingPortalConfig?.color_theme?.secondary_color}`,
                      padding: "20px",
                    }}
                  >
                    Oops! We are not able to show tracking here currently,
                    please
                    <a
                      href={getTrackingLink()}
                      target="_blank"
                      rel="noopener noreferrer"
                      style={{
                        color: "#007bff",
                        textDecoration: "underline",
                        marginLeft: "4px",
                      }}
                    >
                      click the link
                    </a>
                    &nbsp;to track.
                  </Typography>
                )}
            </Box>
          </Stack>
        )}
      <>
        <Dialog
          fullWidth={true}
          open={isSuccessModalOpen}
          onClose={() => setSuccessModalOpen(false)}
          maxWidth="xs"
        >
          <Stack direction={"column"} gap={2} sx={{ padding: "20px" }}>
            <img
              src="/assets/returnbox.svg"
              style={{ paddingTop: "20px", margin: "auto" }}
            />
            <Typography
              sx={{ color: "#000", textAlign: "center", fontSize: 16 }}
            >
              {successModalText}
            </Typography>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                mb: 2,
                mt: 1,
              }}
            >
              <LoadingButton
                variant="outlined"
                sx={{
                  width: "130px",
                  borderColor: "secondary.main",
                  color: "secondary.main",
                }}
                onClick={() => setSuccessModalOpen(false)}
              >
                Close
              </LoadingButton>
            </Box>
          </Stack>
        </Dialog>
      </>
    </Grid>
  );
};
export default RefundEstimateInfo;
