import { decryptData } from "src/dataFetcher/chiper";
import { encConfig } from "src/dataFetcher/config";
import getData from "src/dataFetcher/getDataFetcher";
import postData from "src/dataFetcher/postDataFetcher";
import { backendUserUrl } from "src/utils/backendUrls";
import { backendBaseUrl } from "src/utils/backendUrls";

const get_tenant_url = backendBaseUrl + "tenant/frontend/verify/tenant/company";
const create_order_url = backendBaseUrl + "order";
const create_order_expired_demo_url = backendBaseUrl + "order";
const get_countries_url = backendBaseUrl + "subscription/country_list";
const get_plans_url =
  backendBaseUrl + "subscription/frontEnd/subscription/plan";

const test_conection_url =
  process.env.REACT_APP_BACKEND_BASE_URL + "validate-integration";

const extractData = (result) => {
  if (encConfig.encryption.decrypt) {
      return decryptData(result);
  }
  return result;
  };

export async function getExistantTenant(tenant) {
  try{

    const params = {
      company_name: tenant,
    };
    const result = await getData(get_tenant_url, params, {}, {});
  return result
  }catch(err){
  }
}

export async function createOnboardingUser(data) {
  const result = await postData(
    `${backendUserUrl}frontend/user/signup`,
    null,
    data,
    null
  );
  return JSON.parse(extractData(result))
}

export async function validateOtpCode(data) {
  const result = await postData(
    `${backendUserUrl}frontend/user/verify_email`,
    null,
    data,
    null
  );
  return JSON.parse(extractData(result))
}

export async function resendOtpCode(data) {
  const result = await postData(
    `${backendUserUrl}frontend/user/signup/resend_code`,
    null,
    data,
    null
  );
  return JSON.parse(extractData(result))
}

export async function createOrderOnBoarding(data, user_id) {
  const headers = {
    user_id,
    action: "placeOrder",
  };

  const result = await postData(create_order_url, headers, data, null);
  return JSON.parse(extractData(result))
}
export async function createShopifyOrderOnBoarding(data, user_id) {
  const headers = {
    user_id,
    action: "placeShopifyOrder",
  };

  const result = await postData(create_order_url, headers, data, null);
  return JSON.parse(extractData(result))
}
export async function createOrderExpiredDemo(userInfo, data) {
  const headers = {
    user_id: userInfo.user_id,
    tenant_id: userInfo.tenant_id,
    action: "expiredDemoUser",
  };
  const token = userInfo?.accessToken;
  const result = await postData(create_order_expired_demo_url, headers, data, token);
  return JSON.parse(extractData(result))
}

export async function getCountries() {
  return await getData(get_countries_url);
}

export async function getPlans(isDemoPlan) {
  // const token = userInfo?.accessToken;
  const params = isDemoPlan ? {} : { display: true };
  return await getData(get_plans_url, params, {}, null);
}

export async function testConection(headers) {
  return await getData(test_conection_url, null, headers);
}
