const backendUrl = process.env.REACT_APP_BACKEND_SOCKET_DOMAIN;
import { createContext } from "react";
import socketIO from "socket.io-client";

const SOCKET_URL = backendUrl;

export const socket = socketIO.connect(SOCKET_URL,{
  transports: ['polling'],
  upgrade: false
});
export const SocketContext = createContext();
