import {
  Box,
  Button,
  FormControl,
  FormHelperText,
  IconButton,
  InputAdornment,
  Link,
  OutlinedInput,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import ConfirmationNumberIcon from "@mui/icons-material/ConfirmationNumber";
import EmailIcon from "@mui/icons-material/Email";
import {
  PostPortalLogin,
  sendVerifyEmailOTP,
  verifyEmailOTP,
} from "../PortalApi";
import { getEligibleItems, getOrderInfo } from "src/pages/returns/ReturnsApi";
import typography from "src/theme/typography";
import PolicyIcon from "@mui/icons-material/Policy";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { retrieveLogoUrl } from "src/features/config/configApi";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import CircularLoader from "src/loader/circular/CircularLoader";
import { Controller, useForm } from "react-hook-form";
import { LoadingButton } from "@mui/lab";
import { MuiOtpInput } from "mui-one-time-password-input";
import { ErrorMessagesSchema } from "src/utils/errorMessagesSchema";
import ReturnsLayout from "./ReturnsLayout";
import { useNavigate, useParams } from "react-router-dom";
import useCustomerOrderInfo from "src/features/return_management/hooks/useCustomerOrderInfo";
import { Helmet } from "react-helmet-async";

const ReturnsLogin = () => {
  const navigate = useNavigate();
  const { tenant_name } = useParams();
  const [isCredentialsValid, setIsCredentialsValid] = useState(false);
  const [isGetOtpClicked, setIsGetOtpClicked] = useState(false);
  const [customerInfo, setCustomerInfo] = useState(null);
  const [customerLoginInfo, setCustomerLoginInfo] = useState({});
  const [email, setEmail] = useState("");
  const [isLogin, setIsLogin] = useState(false);
  const [otp, setOtp] = useState(null);
  const [currentOtp, setCurrentOtp] = useState(null);
  const {
    updateCustomerReturnInfo,
    resetBrandPortalInfo,
    brandPortalConfig,
    customAuthInfo,
    tenantInfo,
    customerLoginSuccess,
  } = useCustomerOrderInfo();

  const {
    handleSubmit,
    register,
    control,
    watch,
    setError,
    clearErrors,
    formState: { errors, isValid },
  } = useForm({
    mode: "onChange",
  });

  const [isLoading, setIsLoading] = useState(false);

  function actionLoggedUser(logged_user = false) {
    if (customAuthInfo?.isLoggedIn) {
      const return_info = customAuthInfo?.user_info?.return_info;
      if (return_info != null && return_info?._id) {
        if (logged_user) {
          updateCustomerReturnInfo(return_info);
        }
        navigate(`/returns/${tenant_name}/tracking-items`);
      } else {
        navigate(`/returns/${tenant_name}/create-return`);
      }
    }
  }

  useEffect(() => {
    resetBrandPortalInfo("");
  }, []);

  useEffect(() => {
    if (
      customAuthInfo?.isLoggedIn &&
      customAuthInfo?.isLoggedIn == true &&
      isLogin
    ) {
      actionLoggedUser(true);
    }
  }, [customAuthInfo?.isLoggedIn, isLogin]);

  const onSubmitGetOtp = async (data) => {
    setIsCredentialsValid(true);
  };

  const sendEmailVerifyOTP = async (data) => {
    setEmail(data.email);
    try {
      setIsLoading(true);
      const login_payload = {
        order_number: data.orderId,
        email_id: data.email,
      };
      const loginResponse = await PostPortalLogin(tenantInfo, login_payload);
      setCustomerInfo(loginResponse.data);
      const customer_info = loginResponse.data;
      let otp_payload = {
        tenant_id: tenantInfo.tenant_id,
        action: "customer_return_flow",
        customer_name: customer_info.first_name + " " + customer_info.last_name,
        email_id: login_payload.email_id,
      };
      if (tenantInfo?.is_demo_user) {
        setCustomerLoginInfo(otp_payload);
        const payload = {
          user_info: customer_info,
          return_flow: "guest_user",
          tenant_name: tenant_name,
        };
        customerLoginSuccess(payload);
        setIsLogin(true);
      } else {
        const otp_response = await sendVerifyEmailOTP(otp_payload);
        setCurrentOtp(otp_response.data.otp_code);
        if (otp_response) {
          setIsGetOtpClicked(true);
          setIsCredentialsValid(true);
          setCustomerLoginInfo(otp_payload);
        }
        setIsLoading(false);
      }
      setIsLoading(false);
    } catch (e) {
      // if (e.data.statusMessage === "Invalid Order No")
      //   setError("invalidOrderID", {
      //     type: "credentialsError",
      //     message:
      //     ErrorMessagesSchema.returns.invalidCredentials.invalidOrderId,
      //   });
      // else if (e.data.statusMessage === "Invalid Order No & Email ID")
      //   setError("invalidOrderOrEmailID", {
      //     type: "credentialsError",
      //     message:
      //     ErrorMessagesSchema.returns.invalidCredentials
      //       .invalidEmailOrOrderId,
      //   });
      setError("invalidOrderOrEmailID", {
        type: "credentialsError",
        message:
          ErrorMessagesSchema.returns.invalidCredentials.invalidEmailOrOrderId,
      });
      setIsGetOtpClicked(false);
      setIsCredentialsValid(false);
      setIsLoading(false);
    }
  };
  const onSubmit = async (data) => {
    setIsLoading(true);
    const payload = {
      user_info: customerInfo,
      return_flow: "guest_user",
      tenant_name: tenant_name,
    };
    if (timerExpirationCount <= 0) {
      setError("invalidOtpCode", {
        type: "otpError",
        message: "Your OTP has expired. Please click on resend.",
      });
      setIsLoading(false);
      return;
    }

    try {
      const verify_otp_response = await verifyEmailOTP({
        ...customerLoginInfo,
        otp_code: Math.abs(data.otp),
      });

      // TODO: uncomment this when otp email is sent to user
      // if (verify_otp_response.message === "Invalid OTP Code")
      //   setError("invalidOtp", {
      //     type: "otpError",
      //     message: ErrorMessagesSchema.otpCode.wrong,
      //   });
      data && setOtp(data.otp);

      if (verify_otp_response) {
        if (currentOtp) {
          if (Number(otp) !== currentOtp) {
            const payload = {
              user_info: customerInfo,
              return_flow: "guest_user",
              tenant_name: tenant_name,
            };
            // customerLoginSuccess(payload);
            setError("invalidOtpCode", {
              type: "credentialsError",
              message: ErrorMessagesSchema.otpCode.expired,
            });
          } else {
            const payload = {
              user_info: customerInfo,
              return_flow: "guest_user",
              tenant_name: tenant_name,
            };
            customerLoginSuccess(payload);
            //actionLoggedUser(true);
            setIsLogin(true);
          }
        } else {
          const payload = {
            user_info: customerInfo,
            return_flow: "guest_user",
            tenant_name: tenant_name,
          };
          customerLoginSuccess(payload);
          //actionLoggedUser(true);
          setIsLogin(true);
        }
      } else {
        const payload = {
          user_info: customerInfo,
          return_flow: "guest_user",
          tenant_name: tenant_name,
        };
        // customerLoginSuccess(payload);
        setError("invalidOtpCode", {
          type: "credentialsError",
          message: ErrorMessagesSchema.otpCode.invalid,
        });
      }
      // customerLoginSuccess(payload);
    } catch (error) {
      const payload = {
        user_info: customerInfo,
        return_flow: "guest_user",
        tenant_name: tenant_name,
      };
      // customerLoginSuccess(payload);
      // customerLoginSuccess(payload);
      setError("invalidOtpCode", {
        type: "credentialsError",
        message: ErrorMessagesSchema.otpCode.invalid,
      });
    }
    setIsLoading(false);
  };

  // otp
  const otpLength = 6;
  const secsToResendCode = 30;
  const secsExpirationCode = 300;
  const [timerCount, setTimerCount] = useState(secsToResendCode);
  const [timerExpirationCount, setTimerExpirationCount] =
    useState(secsExpirationCode);

  const handleResendCode = async () => {
    setTimerCount(secsToResendCode);
    let otp_payload = {
      tenant_id: tenantInfo.tenant_id,
      action: "customer_return_flow",
      email_id: email,
    };
    const otp_response = await sendVerifyEmailOTP(otp_payload);
    setCurrentOtp(otp_response.data.otp_code);
    if (otp_response) {
      setIsGetOtpClicked(true);
      setIsCredentialsValid(true);
      setCustomerLoginInfo(otp_payload);
      setTimerExpirationCount(secsExpirationCode);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    if (!timerCount) return;

    const intervalId = setInterval(() => {
      setTimerCount(timerCount - 1);
    }, 1000);

    return () => clearInterval(intervalId);
  }, [timerCount]);

  useEffect(() => {
    // if (!timerExpirationCount) return;

    if (timerExpirationCount === 0) {
      setTimerExpirationCount(0);
      return;
    }

    const intervalId = setInterval(() => {
      setTimerExpirationCount((prev) => prev - 1);
    }, 1000);

    return () => clearInterval(intervalId);
  }, [timerExpirationCount]);

  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60)
      .toString()
      .padStart(2, "0");
    const secs = (seconds % 60).toString().padStart(2, "0");
    return `${minutes}:${secs}`;
  };

  return (
    <>
      <Helmet>
        <title> Beyond Carts </title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: `${brandPortalConfig?.color_theme?.page_bg_color}`,
        }}
      >
        <ReturnsLayout>
          {/* body */}
          {isLoading && <CircularLoader />}
          <Stack alignItems={"center"} justifyContent={"center"} gap={3}>
            {brandPortalConfig?.logo !== null &&
              brandPortalConfig?.logo !== "" && (
                <Box maxWidth={100} sx={{ zIndex: 1 }}>
                  {brandPortalConfig?.logo_url ? (
                    <a
                      href={
                        brandPortalConfig?.logo_url &&
                        (brandPortalConfig?.logo_url.startsWith("http://") ||
                          brandPortalConfig?.logo_url.startsWith("https://"))
                          ? brandPortalConfig?.logo_url
                          : `https://${brandPortalConfig?.logo_url}`
                      }
                    >
                      <img
                        src={retrieveLogoUrl + `${brandPortalConfig?.logo}`}
                      />
                    </a>
                  ) : (
                    <img src={retrieveLogoUrl + `${brandPortalConfig?.logo}`} />
                  )}
                </Box>
              )}
            <FormControl
              component="form"
              onSubmit={handleSubmit(
                isGetOtpClicked ? onSubmitGetOtp : onSubmit
              )}
              sx={{
                display: "flex",
                padding: "40px",
                alignItems: "center",
                gap:
                  errors.invalidOrderID ||
                  errors.invalidOrderOrEmailID ||
                  errors.invalidOtpCode
                    ? 3.5
                    : 4.5,
                gap: 4.5,
                width: { xs: "90%", sm: 460 },
                backgroundColor: "#fff",
                // borderRadius: `${data.brandedAssetsState.portalCornerRadius}`,
                zIndex: 1,
                borderRadius: "12px",
                boxShadow: "0px 6px 30px 0px #00000014",
              }}
            >
              <Typography
                sx={{
                  textAlign: "center",
                  fontWeight: "bold",
                  fontSize: `16px`,
                  color: `${brandPortalConfig?.color_theme?.brand_color}`,
                  lineHeight: 1,
                }}
              >
                {brandPortalConfig?.heading}
              </Typography>

              {(errors.invalidOrderID ||
                errors.invalidOrderOrEmailID ||
                errors.invalidOtpCode) && (
                <Typography
                  sx={{
                    color: "#FF1616",
                    backgroundColor: "#ffd0d0",
                    padding: "4px 8px",
                    borderRadius: "5px",
                    fontSize: "12px",
                  }}
                >
                  {errors.invalidOrderID?.message ||
                    errors.invalidOrderOrEmailID?.message ||
                    errors.invalidOtpCode?.message}
                </Typography>
              )}
              <Stack width={"100%"} gap={2.5}>
                <TextField
                  fullWidth
                  sx={{
                    "& > div > input": { color: "common.black" },
                    // width: { lg: 515 },
                    "& .MuiOutlinedInput-root": {
                      "&:hover fieldset": {
                        borderColor: "primary.dark",
                      },
                      "&.Mui-focused fieldset": {
                        borderColor: "primary.dark",
                      },
                    },
                  }}
                  label="Order Number"
                  name="orderId"
                  // autoFocus
                  {...register("orderId", {
                    required: "Order Number is required.",
                  })}
                  error={
                    errors.orderId ||
                    errors.invalidOrderID ||
                    errors.invalidOrderOrEmailID
                  }
                  helperText={errors?.orderId?.message}
                  onFocus={() =>
                    clearErrors(["invalidOrderID", "invalidOrderOrEmailID"])
                  }
                />
                <TextField
                  fullWidth
                  sx={{
                    "& > div > input": {
                      color: "common.black",
                    },
                    // width: { lg: 515 },
                    "& .MuiOutlinedInput-root": {
                      "&:hover fieldset": {
                        borderColor: "primary.dark",
                      },
                      "&.Mui-focused fieldset": {
                        borderColor: "primary.dark",
                      },
                    },
                  }}
                  label="Email"
                  name="email"
                  autoComplete="email"
                  // autoFocus

                  {...register("email", {
                    required: "Email address is required.",
                    pattern: {
                      value: /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
                      message: "Invalid email address.",
                    },
                  })}
                  error={errors.email || errors.invalidOrderOrEmailID}
                  helperText={errors?.email?.message}
                  style={{ color: "black" }}
                  onFocus={() => clearErrors("invalidOrderOrEmailID")}
                  InputProps={{
                    endAdornment: isValid ? (
                      !isCredentialsValid ? (
                        <InputAdornment
                          position="end"
                          sx={{
                            cursor: "pointer",
                          }}
                        >
                          <LoadingButton
                            onClick={handleSubmit(sendEmailVerifyOTP)}
                          >
                            <Typography
                              sx={{
                                fontFamily: typography.fontFamilySemiBold,
                                color: `${brandPortalConfig?.color_theme?.brand_color}`,
                              }}
                            >
                              Get OTP
                            </Typography>
                          </LoadingButton>
                        </InputAdornment>
                      ) : (
                        <InputAdornment position="end">
                          {timerCount === 0 ? (
                            <LoadingButton onClick={handleResendCode}>
                              <Typography
                                sx={{
                                  fontFamily: typography.fontFamilySemiBold,
                                  // color: `${data.brandedHeaderState.text_color}`,z
                                  color: `${brandPortalConfig?.color_theme?.brand_color}`,
                                }}
                              >
                                Resend
                              </Typography>
                            </LoadingButton>
                          ) : (
                            <Typography
                              sx={{
                                fontFamily: typography.fontFamilySemiBold,
                                // color: `${data.brandedHeaderState.text_color}`,z
                                fontSize: { xs: 12, md: 14 },
                                color: `${brandPortalConfig?.color_theme?.brand_color}`,
                              }}
                            >
                              Resend code in {timerCount} secs
                            </Typography>
                          )}
                        </InputAdornment>
                      )
                    ) : isCredentialsValid ? (
                      <InputAdornment position="end">
                        {timerCount === 0 ? (
                          <LoadingButton onClick={handleResendCode}>
                            <Typography
                              sx={{
                                fontFamily: typography.fontFamilySemiBold,
                                // color: `${data.brandedHeaderState.text_color}`,z
                                color: `${brandPortalConfig?.color_theme?.brand_color}`,
                              }}
                            >
                              Resend
                            </Typography>
                          </LoadingButton>
                        ) : (
                          <Typography
                            sx={{
                              fontFamily: typography.fontFamilySemiBold,
                              // color: `${data.brandedHeaderState.text_color}`,z
                              fontSize: { xs: 12, md: 14 },
                              color: `${brandPortalConfig?.color_theme?.brand_color}`,
                            }}
                          >
                            Resend code in {timerCount} secs
                          </Typography>
                        )}
                      </InputAdornment>
                    ) : (
                      <div></div>
                    ),
                  }}
                />
                {isCredentialsValid && (
                  <Stack gap={2} alignItems={"flex-end"}>
                    <Stack
                      direction="row"
                      gap={{ xs: 2, sm: 2, md: 3 }}
                      className="flexContainer"
                    >
                      <Controller
                        control={control}
                        rules={{
                          validate: (value) => value?.length === otpLength,
                        }}
                        render={({ field, fieldState }) => (
                          <Box>
                            <MuiOtpInput
                              sx={{
                                gap: { xs: 1, md: 2 },
                                input: {
                                  backgroundColor: "#eaeaea",
                                  borderRadius: "inherit",
                                  borderColor: errors.invalidOtpCode
                                    ? "red"
                                    : "inherit",
                                },
                                "& .MuiOutlinedInput-root": {
                                  "& fieldset": {
                                    borderColor: errors.invalidOtpCode
                                      ? "red"
                                      : "inherit",
                                  },
                                  "&:hover fieldset": {
                                    borderColor: errors.invalidOtpCode
                                      ? "red"
                                      : "primary.dark",
                                  },
                                  "&.Mui-focused fieldset": {
                                    borderColor: errors.invalidOtpCode
                                      ? "red"
                                      : "primary.dark",
                                  },
                                },
                              }}
                              {...field}
                              length={otpLength}
                              onFocus={() => clearErrors("invalidOtpCode")}
                              onChange={(value) => {
                                field.onChange(value);
                                setOtp(value);
                              }}
                            />
                          </Box>
                        )}
                        name="otp"
                      ></Controller>
                    </Stack>
                    <Box
                      sx={{
                        fontSize: { xs: 12, md: 14 },
                      }}
                    >
                      {typeof timerExpirationCount === "number" &&
                      timerExpirationCount > 0
                        ? `OTP expires in ${formatTime(timerExpirationCount)}`
                        : "OTP Code has expired. Please click on resend"}
                    </Box>
                  </Stack>
                )}
              </Stack>
              <LoadingButton
                size="large"
                disabled={!isCredentialsValid || !isValid}
                type="submit"
                variant="contained"
                onClick={() => setIsGetOtpClicked(false)}
                sx={{
                  // backgroundColor: `${data.brandedAssetsState.brand_background_color}`,
                  backgroundColor: `${brandPortalConfig?.color_theme?.brand_color}`,
                  // color: `${data.brandedAssetsState.brand_color}`,
                  color: "#fff",
                  width: { xs: 180, sm: 250 },
                  borderRadius: "10px",
                  "&:hover": {
                    backgroundColor: `${brandPortalConfig?.color_theme?.brand_color}`,
                  },
                }}
              >
                Submit
              </LoadingButton>
            </FormControl>
          </Stack>
        </ReturnsLayout>
      </Box>
    </>
  );
};

export default ReturnsLogin;
