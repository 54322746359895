import {useState} from "react";
import {
  Box,
  Divider,
  MenuItem,
  Avatar,
  IconButton,
  Popover,
  Typography,
} from "@mui/material";
import Iconify from "src/components/iconify";
import account from "src/_mock/account";
import {useAppDispatch, useAppSelector} from "src/hooks/hooks";
import {getUserInfo, logoutUser} from "src/features/user/userState";
import ProfileModal from "src/components/modal/ProfileModal";
import {clearSigninState} from "src/features/signin/signin.action";
import {persistStore} from 'redux-persist';
import {persistor} from "../../../index";

export default function AccountPopover() {
  const [open, setOpen] = useState(null);
  const [openProfileModal, setOpenProfileModal] = useState(false);
  const dispatch = useAppDispatch();
  const userInfo = useAppSelector(getUserInfo);

  const handleOpen = (event) => {
    setOpen(event.currentTarget);
  };

  const handleClose = () => {
    setOpen(null);
  };

  const handleLogout = () => {
    dispatch(logoutUser());
    dispatch(clearSigninState());
    persistor.purge();
    // localStorage.removeItem("signinData");
  };

  return (
    <>
      <IconButton
        onClick={handleOpen}
        sx={{
          p: 0,
        }}
      >
        <Avatar src={account.photoURL} alt="photoURL"/>
      </IconButton>

      {openProfileModal && (
        <ProfileModal
          openModal={openProfileModal}
          setOpenModal={setOpenProfileModal}
        />
      )}

      <Popover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleClose}
        anchorOrigin={{vertical: "bottom", horizontal: "right"}}
        transformOrigin={{vertical: "top", horizontal: "right"}}
        PaperProps={{
          sx: {
            p: 0,
            mt: 1.5,
            ml: 0.75,
            width: 200,
            "& .MuiMenuItem-root": {
              typography: "body2",
              borderRadius: 0.75,
            },
          },
        }}
      >
        <Box
          sx={{
            py: {xs: 1.5, md: 1.5},
            px: {xs: 1.6, md: 2.5},
            display: "flex",
            alignItems: "center",
            cursor: "pointer",
            gap: 1.5,
          }}
          onClick={() => setOpenProfileModal(true)}
        >
          <Avatar/>
          <Typography variant="body2" sx={{color: "common.black"}} noWrap>
            {userInfo?.first_name}
          </Typography>
        </Box>

        <Divider sx={{borderStyle: "line"}}/>
        <Box
          sx={{
            py: {xs: 1, md: 2},
            px: {xs: 2.5},
            display: "flex",
            alignItems: "center",
            cursor: "pointer",
            gap: 1.5,
          }}
          onClick={handleLogout}
        >
          <Iconify
            icon="mdi:logout"
            sx={{
              color: "text.disabled",
              width: 30,
              height: 26,
            }}
          />
          <MenuItem sx={{color: "common.black", padding: 0}}>Logout</MenuItem>
        </Box>
      </Popover>
    </>
  );
}
