import {useEffect, useState} from "react";
import {Helmet} from "react-helmet-async";
import axios from "axios";
import {clearSigninState, setSigninData} from "../features/signin/signin.action";
import {useAppDispatch, useAppSelector} from "src/hooks/hooks";
import {updateLoginActionFromShopifyApp} from "../features/user/userState";
import {getToken} from "../utils/extractUserToken";
import {useNavigate} from "react-router-dom";
import {useSelector} from "react-redux";
import {persistor} from "../index";


export default function ShopifyAuthorize() {
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const signinData = useSelector((state) => state.signin.signinData);

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const code = searchParams.get('code');
    const hmac = searchParams.get('hmac');
    const shop = searchParams.get('shop');
    const host = searchParams.get('host');
    const timestamp = searchParams.get('timestamp');
    // const state = searchParams.get('state');
    const fetchData = async () => {
      try {
        setIsLoading(true);
        const response = await axios.get(
          `${process.env.REACT_APP_SHOPIFY_CALLBACK_BASE_URL}/shopify/authorize/callback`,
          {
            params: {
              code: code,
              hmac: hmac,
              shop: shop,
              host,
              timestamp
            }
          }
        );

        const result = response.data;
        if (result?.data) {
          if (result.data?.isLogin && result.data.isLogin) {
            const responseData = result.data.loginData
            dispatch(setSigninData(responseData))
            dispatch(
              updateLoginActionFromShopifyApp({...responseData, loginFlow: 'shopify_app'})
            );
          }
          if (result.data?.isOnboardRequired && result.data.isOnboardRequired) {
            const responseData = result.data.loginData
            const shopifyAppData = result.data.shopifyAppData;
            dispatch(setSigninData(
              {
                ...responseData,
                "fullName": responseData?.first_name + ' ' + responseData?.last_name,
                "name": responseData?.first_name,
                "email": responseData.email,
                "lastName": responseData?.last_name,
                "shippingvolume": null,
                "company": shopifyAppData.name,
                "phone": responseData?.phone_number,
                "manager": "shopify",
                "manageError": false,
                "managerSignShopifyStoreUrl": `https://${shopifyAppData?.domain}`,
                "isShopifyValid": true,
                "isShopifyTested": true,
                "managerSignShopifyApiKey": shopifyAppData?.token,
                "signInSource": "shopify_app",
                "isOnboardRequired": true,
                loginFlow: 'shopify_app'
              }
            ))
            // navigate("/onboarding/carrier");
          }
        }
      } catch (err) {

      } finally {

      }
      setIsLoading(false);
    };
    // Call the async function
    fetchData();

  }, []);
  useEffect(() => {
    if (signinData?.isOnboardRequired && signinData.isOnboardRequired) {
      navigate("/onboarding/carrier");
    }
  }, [signinData]);
  return (
      <>
          <Helmet>
              <title> Redirecting to BeyondCarts </title>
          </Helmet>
          <div style={styles.container}>
              {isLoading ? (
                  <div style={styles.loaderContainer}>
                      <div style={styles.spinner}></div>
                      <p style={styles.text}>Redirecting to BeyondCarts. Please wait...</p>
                  </div>
              ) : (
                  <p></p>
              )}
          </div>
      </>
  );
}


const styles = {
    container: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
        backgroundColor: '#f4f6f8', // Shopify light background
    },
    loaderContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: '40px',
        borderRadius: '12px',
        backgroundColor: '#ffffff',
        boxShadow: '0px 4px 16px rgba(0, 0, 0, 0.1)',
    },
    spinner: {
        width: '40px',
        height: '40px',
        border: '4px solid #f3f3f3',
        borderTop: '4px solid #5c6ac4', // Shopify's primary color
        borderRadius: '50%',
        animation: 'spin 1s linear infinite',
        marginBottom: '20px',
    },
    text: {
        fontSize: '16px',
        color: '#212b36', // Shopify dark gray
        fontWeight: '500',
    },
};

// CSS for spinner animation
const styleSheet = document.styleSheets[0];
styleSheet.insertRule(`
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
`, styleSheet.cssRules.length);
