import { useEffect, useState, useContext } from "react";
import {
  Box,
  Divider,
  Avatar,
  IconButton,
  Popover,
  Typography,
} from "@mui/material";
import { useAppDispatch, useAppSelector } from "src/hooks/hooks";
import { getUserInfo, logoutUser } from "src/features/user/userState";
import NotificationsItem from "./NotificationsItem";
import { useNavigate } from "react-router-dom";
import {
  getNotifications,
  updateReadNotification,
} from "src/features/tracking/trackingApi";
import { SocketContext } from "src/components/socket-handler/sockethandler";
import { groupByArrayData, unixToDateAndTime } from "src/utils/core";
import appUrls from "src/utils/appUrls";

export default function NotificationsPopover() {
  const [open, setOpen] = useState(null);
  const [refreshNotifications, setRefreshNotifications] = useState(null);
  const [notificationCount, setNotificationCount] = useState(0);
  const userInfo = useAppSelector(getUserInfo);
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);
  const socket = useContext(SocketContext);

  const [notificationsData, setNotificationsData] = useState([
    {
      message: "",
      timeAgo: "",
      status: "",
    },
  ]);

  const getNotificationsData = async () => {
    try {
      const response = await getNotifications(
        {
          page_size: 3,
          current_page: 1,
        },
        userInfo
      );
      if (response.statusCode === 200) {
        const sortedData = response.data.items.sort(
          (a, b) =>
            unixToDateAndTime(a.timestamp) - unixToDateAndTime(b.timestamp)
        );
        const groupData = groupByArrayData(sortedData, "read");
        if (groupData.hasOwnProperty("false")) {
          const total_count = groupData["false"].length;
          setNotificationCount(total_count);
        }
        setNotificationsData(sortedData);
      }
    } catch (error) {}
  };

  const updateReadNotificationData = async () => {
    try {
      const response = await updateReadNotification({}, userInfo);
    } catch (error) {}
  };
  useEffect(() => {
    // refresh notifications
    const handleRefreshNotifications = (data) => {
      const document = data.fullDocument;
      setIsOpen(false);
      setNotificationCount((count) => count + 1);
      setRefreshNotifications(document);
    };

    const tenant_id = userInfo.tenant_id;
    socket.on(`notification_${tenant_id}`, handleRefreshNotifications);
    return () => {
      socket.off(`notification_${tenant_id}`, handleRefreshNotifications);
    };
  }, []);

  useEffect(() => {
    getNotificationsData();
  }, [refreshNotifications]);

  const handleOpen = (event) => {
    setNotificationCount(0);
    updateReadNotificationData();
    setOpen(event.currentTarget);
  };

  const handleClose = () => {
    setOpen(null);
  };

  const handleViewAllNotificationsClick = () => {
    handleClose();
    navigate(appUrls.notifications);
  };

  return (
    <>
      <IconButton
        onClick={handleOpen}
        sx={{
          position: "relative",
          p: 0,
          textAlign: "center",
        }}
      >
        <Avatar
          src={"/assets/icons/notifications.png"}
          alt="notifications belt"
        />
        {notificationCount > 0 && (
          <Box
            sx={{
              justifyContent: "center",
              position: "absolute",
              display: "flex",
              alignItems: "center",
              right: "0.125rem",
              top: "-4px",
              height: "1rem",
              width: "1rem",
              backgroundColor: "red",
              fontSize: "0.6rem",
              color: "#fff",
              fontWeight: 700,
              borderRadius: "50%",
            }}
          >
            {notificationCount}
          </Box>
        )}
      </IconButton>

      <Popover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
        PaperProps={{
          sx: {
            p: 0,
            mt: 1.5,
            ml: 0.75,
            width: { xs: 250, md: 350 },
            "& .MuiMenuItem-root": {
              typography: "body2",
              borderRadius: 0.75,
            },
          },
        }}
      >
        {/* title */}
        <Box
          sx={{
            py: { xs: 1.5, md: 1.5 },
            px: { xs: 1.6, md: 2.5 },
            display: "flex",
            alignItems: "center",
            gap: 1,
          }}
        >
          <Typography
            variant="body2"
            sx={{
              color: "secondary.main",
              fontSize: { xs: 18, md: 21 },
            }}
            noWrap
          >
            Notifications
          </Typography>
        </Box>
        <Divider sx={{ borderStyle: "line" }} />

        {notificationsData.map((el,index) => (
          <NotificationsItem
            key={index}
            message={el?.message}
            timeAgo={el?.timestamp}
            status={el?.status}
          />
        ))}

        <Box
          onClick={handleViewAllNotificationsClick}
          sx={{
            py: { xs: 1.5, md: 1.5 },
            px: { xs: 1.6, md: 2.5 },
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            backgroundColor: "rgba(207, 236, 255, 0.4)",
            cursor: "pointer",
            gap: 1,
          }}
        >
          <Typography
            variant="body2"
            sx={{
              color: "secondary.main",
              fontSize: { xs: 16, md: 17 },
            }}
            noWrap
          >
            View all Notifications
          </Typography>
        </Box>
      </Popover>
    </>
  );
}
