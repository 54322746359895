import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {loginCall, updateProfileInfo} from "./userApi";
import {deleteToken, setToken} from "src/utils/extractUserToken";
import {getPlansThunk} from "../plans/plansState";
import {getTenant} from "../usersPage/usersApi";
import {GetImages} from "../../pages/returns/ReturnsApi";
import {shopifyImages} from "../returns/shopifyImages";

// initial state of the user slice in the redux store
const initialState = {
  isAuthenticated: false,
  userInfo: {},
  userError: {
    loginError: false,
    loginErrorMessage: "",
  },
  isLoading: false,
  error: null,
  loginFlow: 'direct'
  // THIS CURRENTLY DOES NOT EXITS :
  // trackingInfo: [],
  // mailScan: [],
};

// validates if a new users wants to loggin for the first time
export const validateLogin = async (data) => {
  let response;
  try {
    response = await loginCall(data);
    return response;
  } catch (error) {
    return error.message;
  }
};

export const login = createAsyncThunk(
  "user/login",
  async (data, {dispatch}) => {
    let tenant_logo = null;
    let createdBy = null;
    let carrier = null;

    const response = await loginCall(data);
    await dispatch(getPlansThunk());

    if (response?.statusCode === 200) {
      try {
        const tenant = await getTenant(response?.data);
        const {statusCode, data} = tenant;
        if (statusCode === 200) {
          tenant_logo = data?.logo_path;
          createdBy = data?.createdBy;
          carrier = data?.carrier;
        }
        GetImages(response?.data).then((res) => {
          if (res) dispatch(shopifyImages(res.data));
        });
      } catch (error) {
        console.log(error);
      }
    }
    return {...response, tenant_logo, createdBy, carrier, loginFlow: data?.loginFlow};
  }
);

export const logoutUser = createAsyncThunk("user/fetchUserLogout", async () => {
  const response = initialState;
  return response;
});

export const updateUserInfo = createAsyncThunk(
  "user/updateUserInfo",
  async (newUserInfo, {getState}) => {
    const currentState = getState();
    const currentUserInfo = currentState.user.userInfo;
    // Update userInfo based on newUserInfo
    const updatedUserInfo = {
      ...currentUserInfo,
      ...newUserInfo,
    };

    try {
      const response = await updateProfileInfo(
        updatedUserInfo,
        currentUserInfo
      );
      // Return the updated userInfo
      return updatedUserInfo;
    } catch (err) {
      return currentUserInfo;
    }
  }
);

export const updateUserPreferences = createAsyncThunk(
  "user/updateUserPreferences",
  async ({trackingInfo, mailScan}, {getState}) => {
    const currentState = getState();
    return {
      ...currentState.user,
      // THIS CURRENTLY DOES NOT EXITS :
      // trackingInfo,
      // mailScan,
    };
  }
);

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    selectProduct: (state, action) => {
      state.selectedProduct = action.payload;
    },
    updateLoginActionFromShopifyApp: (state, action) => {
      state.userInfo = {
        ...action.payload,
        role: 'administrator',
        // tenant_logo: action.payload.tenant_logo,
        // createdBy: action.payload.createdBy,
        // carrier: action.payload.carrier,
        email: null,
      };
      state.isAuthenticated = true;
      state.loginFlow = action.payload?.loginFlow ? action.payload?.loginFlow : 'direct';
      state.userError = {...state.userError, loginError: false};
      state.isLoading = false;
      // THIS CURRENTLY DOES NOT EXITS :
      // state.trackingInfo = action.payload.trackingInfo;
      // state.mailScan = action.payload.mailScan;
      setToken(action?.payload?.accessToken);
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(login.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(login.fulfilled, (state, action) => {
        state.userInfo = {
          ...action.payload.data,
          tenant_logo: action.payload.tenant_logo,
          createdBy: action.payload.createdBy,
          carrier: action.payload.carrier,
          email: action.meta.arg.email,
        };
        state.isAuthenticated = true;
        state.userError = {...state.userError, loginError: false};
        state.isLoading = false;
        state.loginFlow = action.payload?.loginFlow ? action.payload?.loginFlow : 'direct';
        // THIS CURRENTLY DOES NOT EXITS :
        // state.trackingInfo = action.payload.trackingInfo;
        // state.mailScan = action.payload.mailScan;
        setToken(action?.payload?.data?.accessToken);
      })
      .addCase(login.rejected, (state, action) => {
        state.userError = {
          ...state.userError,
          loginError: true,
          loginErrorMessage:
            action?.error?.message === "Invalid credentials"
              ? "Invalid Email or Password."
              : action?.error?.message || "Invalid Email or Password.",
        };
        state.isLoading = false;
        state.error = action.error.message;
      })
      .addCase(logoutUser.fulfilled, (state, action) => {
        state.isAuthenticated = initialState.isAuthenticated;
        state.userInfo = initialState.userInfo;
        // THIS CURRENTLY DOES NOT EXITS :
        // state.trackingInfo = initialState.trackingInfo;
        // state.mailScan = initialState.mailScan;
        deleteToken();
      })
      .addCase(updateUserInfo.fulfilled, (state, action) => {
        state.userInfo = action.payload;
      });
    // .addCase(updateUserPreferences.fulfilled, (state, action) => {
    // state.trackingInfo = action.payload.trackingInfo;
    // state.mailScan = action.payload.mailScan;
    // });
  },
});

export const {selectProduct, updateLoginActionFromShopifyApp} = userSlice.actions;

// selectors
export const getAllUserDetails = (state) => state?.user;
export const getUserInfo = (state) => state?.user?.userInfo;
export const getUserError = (state) => state?.user?.userError;

export default userSlice.reducer;
