import {useEffect, useState, useRef} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useForm} from "react-hook-form";
import {Helmet} from "react-helmet-async";
import {
  TextField,
  Box,
  Button,
  Typography,
  Snackbar,
  Alert,
  FormControl,
  FormLabel,
  FormControlLabel,
  Radio,
  RadioGroup,
  Select,
  MenuItem,
  InputLabel,
  Switch,
} from "@mui/material";
import {getUserInfo, updateUserInfo,getAllUserDetails} from "../features/user/userState";
import {
  getConfigData,
  getConfigUpdated,
} from "../features/config/configState";
import {useAppDispatch, useAppSelector} from "../hooks/hooks";
import typography from "src/theme/typography";
import palette from "src/theme/palette";
import SigninCarrier from "src/sections/auth/signin/formsContainer/carrier/signinCarrier/signinCarrier";
import SigninManage from "src/sections/auth/signin/formsContainer/manage/signinManage/signinManage";
import {setSigninData} from "src/features/signin/signin.action";
import {
  getTenant,
  getTenantUser,
  getUsersList,
} from "src/features/usersPage/usersApi";
import {getPlans} from "src/features/onborading/onboardingApi";
import {
  updateLogo,
  updateTenantInfo,
  retrieveLogoUrl,
  updateEmailPreferences,
  updateCarrierManagerInfo,
} from "src/features/config/configApi";
import ModalComponent from "src/components/modal/ModalComponent";
import {LoadingButton} from "@mui/lab";
import {getPlansInfo} from "src/features/plans/plansState";
import planNames from "src/utils/planNames";
import {unixToDate} from "src/utils/core";
import ManageForm from "src/sections/auth/signin/formsContainer/manage/manage";
import Carrier from "src/sections/auth/signin/formsContainer/carrier/carrier";
import InfoLogo from "src/components/info-logo/info-logo";
import {
  decrementAwaitingApiCounter,
  incrementAwaitingApiCounter,
} from "src/features/ui/uiState";

const styleInput = {
  input: {color: palette.common.black},
  width: "100%",
  marginTop: "10px",

  "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
    borderColor: "secondary.light",
  },
  "& .MuiInputLabel-formControl": {
    color: "#00000099",
  },
  "& .MuiFormLabel-root-MuiInputLabel-root.Mui-focused": {
    color: "#00000099",
  },
  "& .MuiFormHelperText-root": {
    textAlign: "left",
    fontSize: "14px",
  },
};

const btnStyle = {
  width: {xs: 218, lg: 265},
  height: 48,
  boxShadow: "none",
  fontSize: 16,
  fontFamily: typography.fontFamilySemiBold,
};

const textStyle = {
  color: palette.common.black,
  fontSize: {xs: 16, md: 18},
};

export default function ConfigPage() {
  const {reset} = useForm();
  const dispatchRedux = useDispatch();
  const inputFile = useRef(null);
  const signinData = useSelector((state) => state.signin.signinData);

  const [showConfigUpdated, setShowConfigUpdated] = useState(false);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [saveEnable, setsaveEnable] = useState(true);

  const [isConfig, setIsConfig] = useState(false);
  const [isConfigCarrierOrderManager, setIsConfigCarrierOrderManager] =
    useState(false);
  const [userConfigInfo, setUserConfigInfo] = useState({});
  const [tenantConfigInfo, setTenantConfigInfo] = useState({});
  const [contactEmail, setContactEmail] = useState("");
  const [contactEmailError, setContactEmailError] = useState(false);
  const [shippingVolume, setShippingVolume] = useState("");
  const [plans, setPlans] = useState([]);
  const [selectedPlan, setSelectedPlan] = useState("");
  const [planSwitch, setPlanSwitch] = useState("");
  const [logoPath, setLogoPath] = useState();
  const [file, setFile] = useState();
  const [fileError, setFileError] = useState(false);
  const [isLogoUpdate, setIsLogoUpdate] = useState(false);
  const [isActivate, setIsActivate] = useState("active");
  const [isSwitched, setIsSwitched] = useState(false);
  const [adminUsers, setAdminUsers] = useState([]);
  const [redirect, setRedirect] = useState("");

  const [emailPreferences, setEmailPreferences] = useState({
    first_scan_email: true,
    delivered_email: true,
    out_for_delivery_email: true,
  });

  const [isEmailPreferencesUpdate, setIsEmailPreferencesUpdate] =
    useState(false);

  const dispatch = useAppDispatch();
  const userInfo = useAppSelector(getUserInfo);
  const userAllInfo = useAppSelector(getAllUserDetails);
  const configData = useAppSelector(getConfigData);
  const isConfigUpdated = useAppSelector(getConfigUpdated);

  const plansInfo = useAppSelector(getPlansInfo);
  const isDemoPlan = userInfo?.plan_id === plansInfo[planNames.demo]?._id;

  const headers = {
    tenant_id: userInfo?.tenant_id,
    user_id: userInfo?.user_id,
  };

  const defaultValue = {
    REFRESH_STEP_FUNC_ARN: configData?.aws?.refresh_step_func_arn || "",
    BUCKET_NAME: configData?.aws?.bucket_name || "",
    BUCKET_FOLDER_NAME: configData?.aws?.bucket_folder_name || "",
    ITERABLE_API_KEY: configData?.iterable?.api_key || "",
    ITERABLE_URL: configData?.iterable?.url || "",
    ITERABLE_CAMPAIGN_ID: configData?.iterable?.campaign_id || "",
    SERVICE_UPS_USERNAME: configData?.ups?.ups_username || "",
    ACCESS_TOKEN: configData?.magento?.access_token || "",
    BASE_URL: configData?.website?.base_url || "",
    MAGENTO_BASE_URL: configData?.magento?.magento_base_url || "",
    EMAIL_FROM: configData?.report?.email_from || "",
    EMAIL_TO: configData?.report?.email_to || "",
    IS_PROD: configData?.website?.is_prod || "",

    SERVICE_UPS_LICENCE_NUMBER: configData?.ups?.ups_license_no || "",
    SERVICE_UPS_PASSWORD: configData?.ups?.ups_password || "",
    SERVICE_USPS_USER_ID: configData?.usps?.usps_user_id || "",
    // TODO: make sure this is working
    SERVICE_FEDEX_USERNAME: configData?.fedex?.fedex_username || "",
    SERVICE_FEDEX_LICENCE_NUMBER: configData?.fedex?.fedex_license_no || "",
    SERVICE_FEDEX_PASSWORD: configData?.fedex?.fedex_password || "",
  };

  const validateEmail = (email) => {
    return /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g.test(email);
  };

  const onCloseExportInfo = () => {
    setShowConfigUpdated(false);
  };

  const onChangeClick = (name) => {
    setRedirect(name);
    dispatchRedux(
      setSigninData({
        ...signinData,
        user_id: userInfo?.user_id,
      })
    );
    const carriers = ["ups", "usps", "fedex", "dhl"];
    const orders = ["magento", "shopify", "shipstation"];

    if (carriers.includes(name)) {
      setIsConfig("carrier");
    }
    if (orders.includes(name)) {
      setIsConfig("manager");
    }
  };

  const handleUpdateCarrierAndOrderManager = (name) => {
    dispatchRedux(
      setSigninData({
        ...signinData,
        user_id: userInfo?.user_id,
      })
    );
    name === "carrier"
      ? setIsConfigCarrierOrderManager("carrier")
      : setIsConfigCarrierOrderManager("manager");
  };

  const onSaveClick = () => {
    if (checkSignCarrier()) return;
    if (checkSignManager()) return;

    dispatch(incrementAwaitingApiCounter());

    const data = {};

    if (signinData.carrier.usps) {
      data.carrier = {
        ...data.carrier,
        usps: {
          is_label_generate: signinData.carrierSignUSPSIsLabelGenerate,
          user_name: signinData.carrierSignUSPSUsername,
          password: signinData.carrierSignUSPSPassword,
          client_key: signinData.carrierSignUSPSClientId,
          client_secret: signinData.carrierSignUSPSClientSecret,
          ...(signinData.carrierSignUSPSIsLabelGenerate && {
            crid: signinData.carrierSignUSPSCRID,
            manifest_id: signinData.carrierSignUSPSManifestMID,
            mid: signinData.carrierSignUSPSMID,
            account_number: signinData.carrierSignUSPSAccountNumber,
          }),
        },
      };
    }

    if (signinData.carrier.ups) {
      data.carrier = {
        ...data.carrier,
        ups: {
          user_name: signinData.carrierSignUPSUsername,
          password: signinData.carrierSignUPSPassword,
          client_key: signinData.carrierSignUPSClientId,
          client_secret: signinData.carrierSignUPSClientSecret,
        },
      };
    }

    if (signinData.carrier.fedex) {
      data.carrier = {
        ...data.carrier,
        fedex: {
          user_name: signinData.carrierSignFedexUsername,
          password: signinData.carrierSignFedexPassword,
          client_key: signinData.carrierSignFedexClientId,
          client_secret: signinData.carrierSignFedexClientSecret,
        },
      };
    }

    if (signinData.carrier.dhl) {
      data.carrier = {
        ...data.carrier,
        dhl: {
          user_name: signinData.carrierSignDHLUsername,
          password: signinData.carrierSignDHLPassword,
          api_key: signinData.carrierSignDHLApiKey,
          api_secret: signinData.carrierSignDHLApiSecret,
        },
      };
    }

    if (signinData.manager === "magento") {
      data.order_management = {
        magento: {
          store_url: signinData.managerSignMagentoUrl,
          access_token: signinData.managerSignMagentoToken,
        },
      };
    } else if (signinData.manager === "shopify") {
      data.order_management = {
        shopify: {
          access_token: signinData.managerSignShopifyApiKey,
          store_url: signinData.managerSignShopifyStoreUrl,
        },
      };
    } else if (signinData.manager === "shipstation") {
      data.order_management = {
        ship_station: {
          api_key: signinData.managerSignShipStationApiKey,
          api_secret: signinData.managerSignShipStationApiSecret,
        },
      };
    }

    updateCarrierManagerInfo(data, userInfo)
      .then((r) => {
        setShowConfirmation(true);
        setIsConfig(false);
        dispatch(decrementAwaitingApiCounter());
      })
      .catch(() => {
        setIsConfig(false);
        dispatch(decrementAwaitingApiCounter());
      });
  };

  const checkSignCarrier = () => {
    if (signinData.carrier.usps) {
      if (!signinData.carrierSignUSPSUsername) {
        dispatch(
          setSigninData({
            ...signinData,
            carrierSignUSPSUsernameError: true,
          })
        );
        return true;
      }

      if (!signinData.carrierSignUSPSPassword) {
        dispatch(
          setSigninData({
            ...signinData,
            carrierSignUSPSPasswordError: true,
          })
        );
        return true;
      }

      if (!signinData.carrierSignUSPSClientId) {
        dispatch(
          setSigninData({
            ...signinData,
            carrierSignUSPSClientIdError: true,
          })
        );
        return true;
      }

      if (!signinData.carrierSignUSPSClientSecret) {
        dispatch(
          setSigninData({
            ...signinData,
            carrierSignUSPSClientSecretError: true,
          })
        );
        return true;
      }
    }

    if (signinData.carrier.ups) {
      if (!signinData.carrierSignUPSUsername) {
        dispatch(
          setSigninData({
            ...signinData,
            carrierSignUPSUsernameError: true,
          })
        );
        return true;
      }
      if (!signinData.carrierSignUPSPassword) {
        dispatch(
          setSigninData({
            ...signinData,
            carrierSignUPSPasswordError: true,
          })
        );
        return true;
      }
    }

    if (signinData.carrier.fedex) {
      if (!signinData.carrierSignFedexUsername) {
        dispatch(
          setSigninData({
            ...signinData,
            carrierSignFedexUsernameError: true,
          })
        );
        return true;
      }
      if (!signinData.carrierSignFedexPassword) {
        dispatch(
          setSigninData({
            ...signinData,
            carrierSignFedexPasswordError: true,
          })
        );
        return true;
      }
    }

    if (signinData.carrier.dhl) {
      if (!signinData.carrierSignDHLUsername) {
        dispatch(
          setSigninData({
            ...signinData,
            carrierSignDHLUsernameError: true,
          })
        );
        return true;
      }

      if (!signinData.carrierSignDHLPassword) {
        dispatch(
          setSigninData({
            ...signinData,
            carrierSignDHLPasswordError: true,
          })
        );
        return true;
      }
    }
  };

  const checkSignManager = () => {
    if (!signinData.managerSignMagentoUrl && signinData.manager === "magento") {
      dispatch(
        setSigninData({
          ...signinData,
          managerSignMagentoUrlErrors: true,
        })
      );
      return true;
    }

    if (
      !signinData.managerSignMagentoToken &&
      signinData.manager === "magento"
    ) {
      dispatch(
        setSigninData({
          ...signinData,
          managerSignMagentoTokenErrors: true,
        })
      );
      return true;
    }

    if (
      !signinData.managerSignShopifyApiKey &&
      signinData.manager === "shopify"
    ) {
      dispatch(
        setSigninData({
          ...signinData,
          managerSignShopifyApiKeyErrors: true,
        })
      );
      return true;
    }

    if (
      !signinData.managerSignShipStationApiKey &&
      signinData.manager === "shipstation"
    ) {
      dispatch(
        setSigninData({
          ...signinData,
          managerSignShipStationApiKeyErrors: true,
        })
      );
      return true;
    }

    if (
      !signinData.managerSignShipStationApiSecret &&
      signinData.manager === "shipstation"
    ) {
      dispatch(
        setSigninData({
          ...signinData,
          managerSignShipStationApiSecretErrors: true,
        })
      );
      return true;
    }
  };

  const onUpdateLogoClick = () => {
    setFileError(false);
    const {current} = inputFile;
    (current || {
      click: () => {
      }
    }).click();
  };

  const handleTenantInfoUpdate = () => {
    if (!contactEmail || !validateEmail(contactEmail)) {
      setContactEmailError(true);
      return;
    }
    dispatch(incrementAwaitingApiCounter());

    const data = {
      shipping_volume: shippingVolume,
      status: isActivate,
      contact_info: {
        email: contactEmail,
      },
      plan_id: planSwitch,
      switched_to_premium:
        planSwitch === plansInfo[planNames.premium]?._id && isSwitched
          ? true
          : false,
    };

    updateTenantInfo(data, userInfo)
      .then((res) => {
        setIsSwitched(false);
        const {statusCode} = res;

        if (statusCode === 200) {
          getTenantUser(null, headers, userInfo?.user_id, userInfo)
            .then((r) => {
              const {data, statusCode} = r;

              if (statusCode === 200) {
                setUserConfigInfo(data);
              }
            })
            .catch((e) => console.log(e));

          getTenant(userInfo)
            .then((r) => {
              const {statusCode, data} = r;

              if (statusCode === 200) {
                setTenantConfigInfo(data);
                setShippingVolume(data.shipping_volume);
                setContactEmail(data.contact_info.email);
                setSelectedPlan(data.plan_id);
                setIsActivate(data.status);
                setLogoPath(
                  data.logo_path ? retrieveLogoUrl + data.logo_path : null
                );

                dispatch(decrementAwaitingApiCounter());

                dispatch(updateUserInfo(data))
                  .then(() => {
                    setShowConfirmation(true);
                  })
                  .catch((err) => {
                  });
              }
            })
            .catch((e) => {
              dispatch(decrementAwaitingApiCounter());
            });
        }
      })
      .catch((e) => {
        dispatch(decrementAwaitingApiCounter());
      });
  };

  const handleUpdateLogo = () => {
    if (!file) {
      setFileError(true);
      return;
    }

    dispatch(incrementAwaitingApiCounter());

    fetch(file)
      .then((res) => res.blob())
      .then((blob) => {
        const file = new File([blob], "company_logo", {
          type: "image/png",
        });

        updateLogo(file, userInfo)
          .then((r) => {
            const {statusCode, data} = r;

            if (statusCode === 200) {
              const {file_path} = data;
              dispatch(updateUserInfo({tenant_logo: file_path}))
                .then(() => {
                  dispatch(decrementAwaitingApiCounter());
                  setIsLogoUpdate(true);
                  setShowConfirmation(true);
                })
                .catch((e) => {
                  dispatch(decrementAwaitingApiCounter());
                });
            }
          })
          .catch((e) => {
            dispatch(decrementAwaitingApiCounter());
          });
      });
  };

  const handleCarrierManagerLoad = ({carrier, order_management}) => {
    const data = {};

    if (carrier.ups) {
      data.carrierSignUPSUsername = carrier.ups.user_name;
      data.carrierSignUPSPassword = carrier.ups.password;
      data.carrierSignUPSClientId = carrier.ups.client_key;
      data.carrierSignUPSClientSecret = carrier.ups.client_secret;
      data.carrier = {...data.carrier, ups: true};
    }

    if (carrier.usps) {
      data.carrierSignUSPSIsLabelGenerate = carrier.usps.is_label_generate;
      data.carrierSignUSPSUsername = carrier.usps.user_name;
      data.carrierSignUSPSPassword = carrier.usps.password;
      data.carrierSignUSPSClientId = carrier.usps.client_key;
      data.carrierSignUSPSClientSecret = carrier.usps.client_secret;

      if (carrier.usps.is_label_generate) {
        data.carrierSignUSPSCRID = carrier.usps.crid;
        data.carrierSignUSPSManifestMID = carrier.usps.manifest_id;
        data.carrierSignUSPSMID = carrier.usps.mid;
        data.carrierSignUSPSAccountNumber = carrier.usps.account_number;
      }

      data.carrier = {...data.carrier, usps: true};
    }

    if (carrier.fedex) {
      data.carrierSignFedexUsername = carrier.fedex.user_name;
      data.carrierSignFedexPassword = carrier.fedex.password;
      data.carrierSignFedexClientId = carrier.fedex.client_key;
      data.carrierSignFedexClientSecret = carrier.fedex.client_secret;
      data.carrier = {...data.carrier, fedex: true};
    }

    if (carrier.dhl) {
      data.carrierSignDHLUsername = carrier.dhl.user_name;
      data.carrierSignDHLPassword = carrier.dhl.password;
      data.carrierSignDHLApiKey = carrier.dhl.api_key;
      data.carrierSignDHLApiSecret = carrier.dhl.api_secret;
      data.carrier = {...data.carrier, dhl: true};
    }

    if (order_management.magento) {
      data.managerSignMagentoUrl = order_management.magento.store_url;
      data.managerSignMagentoToken = order_management.magento.access_token;
      data.manager = "magento";
    }

    if (order_management.shopify) {
      data.managerSignShopifyStoreUrl = order_management.shopify.store_url;
      data.managerSignShopifyApiKey = order_management.shopify.access_token;
      data.manager = "shopify";
    }

    if (order_management.ship_station) {
      data.managerSignShipStationApiKey = order_management.ship_station.api_key;
      data.managerSignShipStationApiSecret =
        order_management.ship_station.api_secret;
      data.manager = "shipstation";
    }

    data.isFromConfig = true;
    dispatchRedux(setSigninData(data));
  };

  const handleUpdateEmailPreferences = async () => {
    dispatch(incrementAwaitingApiCounter());

    try {
      const response = await updateEmailPreferences(emailPreferences, userInfo);
      dispatch(decrementAwaitingApiCounter());

      if (response?.status === 200) {
        setIsEmailPreferencesUpdate(true);
        setShowConfirmation(true);

        getTenantUser(null, headers, userInfo?.user_id, userInfo)
          .then((r) => {
            const {data, statusCode} = r;

            if (statusCode === 200) {
              setUserConfigInfo(data);
            }
          })
          .catch((e) => console.log(e));

        getTenant(userInfo)
          .then((r) => {
            const {statusCode, data} = r;

            if (statusCode === 200) {
              setEmailPreferences({
                delivered_email: data.delivered_email,
                first_scan_email: data.first_scan_email,
                out_for_delivery_email: data.out_for_delivery_email,
              });
            }
          })
          .catch((e) => console.log(e));
      }
    } catch (error) {
      dispatch(decrementAwaitingApiCounter());
      console.log(error);
    }
  };

  const handleCloseModal = () => {
    setShowConfirmation(false);
    setIsEmailPreferencesUpdate(false);
    setIsLogoUpdate(false);
    window.location.reload();
  };

  useEffect(() => {
    dispatch(incrementAwaitingApiCounter());

    getTenantUser(null, headers, userInfo?.user_id, userInfo)
      .then((r) => {
        const {data, statusCode} = r;
        if (statusCode === 200) {
          setUserConfigInfo(data);
        }
      })
      .catch((e) => console.log(e));

    getTenant(userInfo)
      .then((r) => {
        const {statusCode, data} = r;

        if (statusCode === 200) {
          setTenantConfigInfo(data);
          setShippingVolume(data?.shipping_volume);
          setContactEmail(data?.contact_info.email);
          setSelectedPlan(data?.plan_id);
          setPlanSwitch(data?.plan_id);
          setIsActivate(data?.status);
          setLogoPath(data.logo_path ? retrieveLogoUrl + data.logo_path : null);
          setEmailPreferences({
            delivered_email: data?.delivered_email,
            first_scan_email: data?.first_scan_email,
            out_for_delivery_email: data?.out_for_delivery_email,
          });

          handleCarrierManagerLoad(data);
        }
      })
      .catch((e) => console.log(e));

    getPlans(isDemoPlan)
      .then((r) => {
        const {data, statusCode} = r;
        if (statusCode === 200) setPlans(data);
        dispatch(decrementAwaitingApiCounter());
      })
      .catch((error) => {
        console.log(error);
      });

    getUsersList(userInfo)
      .then((r) => {
        const {data, statusCode} = r;
        if (statusCode === 200) {
          const filteredArray = data.filter((r) => r.role === "administrator");
          setAdminUsers(filteredArray);
        }
      })
      .catch((e) => console.log(e));
  }, []);

  useEffect(() => {
    setShowConfigUpdated(isConfigUpdated);
  }, [isConfigUpdated]);

  useEffect(() => {
    reset(defaultValue);
  }, [reset, configData]);

  return (
    <Box>
      <Helmet>
        <title> Configuration | BeyondCarts </title>
      </Helmet>

      {!isConfig && !isConfigCarrierOrderManager && (
        <Box
          sx={{
            marginTop: {xs: 1, md: 3},
            paddingX: {xs: 1, md: 3},
            paddingY: {xs: 2, md: 3},
            border: {xs: "none", md: "1px solid #c4c4c4"},
            borderRadius: "10px",
            display: "flex",
            flexDirection: "column",
            gap: 4,
          }}
        >
          <Box
            sx={{
              display: "grid",
              gridTemplateColumns: {
                xs: "repeat(1, 1fr)",
                sm: "repeat(2, 1fr)",
                xl: "repeat(3, 1fr)",
              },
              gap: {xs: 4, sm: 2, lg: 4},
              justifyContent: "center",
            }}
          >
            <Box
              sx={{
                display: "flex",
                gap: {xs: 2, md: 3},
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                maxWidth: "700px",
                paddingX: {xs: 2, lg: 4},
                paddingY: {xs: 3, lg: 4},
                paddingBottom: "0",
                border: "1px solid #c4c4c4",
                borderRadius: "10px",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  gap: {xs: 1.5, md: 2},
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <h3
                  style={{
                    ...typography.h3,
                    textAlign: "center",
                    width: "100%",
                  }}
                >
                  Company and Contact Information
                </h3>

                <Typography sx={{...textStyle, width: "100%"}}>
                  Company name:{" "}
                  <span
                    style={{
                      fontSize: {xs: 16, md: 18},
                      fontWeight: "bold",
                    }}
                  >
                    {tenantConfigInfo?.company_name}
                  </span>
                </Typography>

                <Typography sx={{...textStyle, width: "100%"}}>
                  Enrolled at: {unixToDate(tenantConfigInfo?.enrolled_at)}
                </Typography>

                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    columnGap: "20px",
                    width: "100%",
                  }}
                >
                  <Typography
                    sx={{
                      ...textStyle,
                      marginTop: 0,
                    }}
                  >
                    Status:
                  </Typography>
                  <Switch
                    checked={isActivate === "active" ? true : false}
                    size="medium"
                    onChange={(event) =>
                      setIsActivate(
                        event.target.checked ? "active" : "Inactive"
                      )
                    }
                  ></Switch>
                </Box>

                {adminUsers.length > 1 ? (
                  <FormControl fullWidth sx={styleInput}>
                    <InputLabel id="credit-month-select-email">
                      Primary contact
                    </InputLabel>
                    <Select
                      labelId="credit-month-select-email"
                      value={contactEmail}
                      label="Primary contact"
                      onChange={(e) => setContactEmail(e.target.value)}
                      sx={{
                        ...styleInput,
                        color: palette.common.black,
                        marginTop: 0,
                      }}
                    >
                      {adminUsers.map((a, index) => (
                        <MenuItem
                          key={index}
                          value={a.user_name}
                          sx={{color: palette.common.black}}
                        >
                          {`${a.first_name} ${a.last_name}`}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                ) : (
                  <TextField
                    sx={styleInput}
                    label="Primary contact"
                    value={contactEmail}
                    disabled
                    error={contactEmailError}
                    helperText={contactEmailError && "Email not valid."}
                    onChange={(e) => setContactEmail(e.target.value)}
                    onFocus={() => setContactEmailError(false)}
                    inputProps={{maxLength: 40}}
                  />
                )}

                <FormControl as="fieldset" fullWidth>
                  <FormLabel
                    id="demo-radio-buttons-group-label"
                    sx={{
                      color: "black !important",
                      paddingBottom: 0.5,
                      fontSize: {xs: 16, md: 18},
                    }}
                  >
                    Monthly Shipping Volume
                  </FormLabel>

                  <RadioGroup
                    aria-labelledby="demo-radio-buttons-group-label"
                    defaultValue="female"
                    name="radio-buttons-group"
                    value={shippingVolume}
                    onClick={(e) => {
                      if (e.target.value === shippingVolume) {
                        setShippingVolume("");
                      } else {
                        setShippingVolume(e.target.value);
                      }
                    }}
                  >
                    <FormControlLabel
                      value="1-2500"
                      control={<Radio sx={{paddingY: 0.5}}/>}
                      label="1-2500"
                    />
                    <FormControlLabel
                      value="2501-5000"
                      control={<Radio sx={{paddingY: 0.3}}/>}
                      label="2501-5000"
                    />
                    <FormControlLabel
                      value="5000&Above"
                      control={<Radio sx={{paddingY: 0.5}}/>}
                      label="5000 & Above"
                    />
                  </RadioGroup>
                </FormControl>

                {/* <Box
                sx={{
                  // height: 150,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              > */}
              </Box>
              <Button
                size="large"
                type="button"
                variant="contained"
                disabled={isDemoPlan}
                sx={{
                  bgcolor: saveEnable ? "primary.light" : "info.button",
                  color: saveEnable ? "common.white" : "common.black",
                  ...btnStyle,
                  backgroundColor: palette.secondary.main,
                }}
                onClick={handleTenantInfoUpdate}
              >
                Update
              </Button>
              {/* </Box> */}
            </Box>

            <Box
              sx={{
                display: "flex",
                gap: 2,
                justifyContent: "center",
                maxWidth: "700px",
                paddingX: {xs: 2, lg: 4},
                paddingY: {xs: 3, lg: 4},
                paddingBottom: "0",
                border: "1px solid #c4c4c4",
                borderRadius: "10px",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                  alignItems: "center",
                  // width: "300px",
                  gap: 3,
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    // width: "300px",
                    gap: {xs: 2, md: 3},
                  }}
                >
                  <h3
                    style={{
                      ...typography.h3,
                      textAlign: "center",
                      width: "100%",
                    }}
                  >
                    Logo
                  </h3>

                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      gap: {xs: 2, md: 4},
                    }}
                  >
                    <Typography
                      sx={{
                        textAlign: "center",
                      }}
                    >
                      (Click on the image below to browse for new logo file)
                    </Typography>

                    <Box
                      sx={{
                        maxWidth: {xs: "200px", md: "300px"},
                        margin: "auto",
                      }}
                    >
                      <img
                        style={{
                          display: "block",
                          // height: "auto",
                          // width: 'auto',
                          maxHeight: "200px",
                          cursor: "pointer",
                        }}
                        src={
                          file
                            ? file
                            : logoPath
                              ? logoPath
                              : "/assets/no-image.png"
                        }
                        onClick={onUpdateLogoClick}
                      />
                    </Box>

                    {fileError && (
                      <Typography
                        sx={{
                          color: fileError ? "red" : "transparent",
                          textAlign: "center",
                        }}
                      >
                        You must select one file.
                      </Typography>
                    )}
                  </Box>

                  <input
                    type="file"
                    id="file"
                    accept="image/*"
                    onChange={(e) => {
                      let reader = new FileReader();
                      try {
                        reader.readAsDataURL(e.target.files[0]);
                        reader.onloadend = function () {
                          setFile(reader.result);
                        };
                      } catch (error) {
                      }
                    }}
                    ref={inputFile}
                    hidden
                  />
                </Box>
                <Box
                  sx={{
                    // height: 150,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Button
                    size="large"
                    type="button"
                    variant="contained"
                    sx={{
                      bgcolor: saveEnable ? "primary.light" : "info.button",
                      color: saveEnable ? "common.white" : "common.black",
                      ...btnStyle,
                      backgroundColor: palette.secondary.main,
                    }}
                    onClick={handleUpdateLogo}
                  >
                    Update
                  </Button>
                </Box>
              </Box>
            </Box>

            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                paddingX: {xs: 2, lg: 4},
                paddingY: {xs: 3, lg: 4},
                paddingBottom: "0",
                maxWidth: "700px",
                gridColumn: {sm: "span 2", xl: "unset"},
                border: "1px solid #c4c4c4",
                borderRadius: "10px",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "space-between",
                  gap: 3,
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: {xs: 1, md: 3},
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <h3
                      style={{
                        ...typography.h3,
                        textAlign: "center",
                        width: "100%",
                      }}
                    >
                      Email Preferences
                    </h3>
                    <InfoLogo
                      infoTxt="Select the type of emails end customers want to receive."
                      contentPositionStyle={{
                        top: "140%",
                        left: " -480%",
                        backgroundColor: "white",
                      }}
                    />
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      gap: {xs: 0, md: 1},
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                      }}
                    >
                      <Typography
                        sx={{
                          ...textStyle,
                          marginTop: 0,
                        }}
                      >
                        Carrier Origin Scan
                      </Typography>
                      <Switch
                        checked={emailPreferences.first_scan_email}
                        onChange={(event) =>
                          setEmailPreferences((prev) => {
                            return {
                              ...prev,
                              first_scan_email: event.target.checked,
                            };
                          })
                        }
                      />
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                      }}
                    >
                      <Typography sx={{...textStyle, margin: 0}}>
                        Out For Delivery
                      </Typography>
                      <Switch
                        checked={emailPreferences.out_for_delivery_email}
                        onChange={(event) =>
                          setEmailPreferences((prev) => {
                            return {
                              ...prev,
                              out_for_delivery_email: event.target.checked,
                            };
                          })
                        }
                      />
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                      }}
                    >
                      <Typography
                        sx={{
                          ...textStyle,
                          margin: 0,
                        }}
                      >
                        Delivered Email
                      </Typography>
                      <Switch
                        checked={emailPreferences.delivered_email}
                        onChange={(event) =>
                          setEmailPreferences((prev) => {
                            return {
                              ...prev,
                              delivered_email: event.target.checked,
                            };
                          })
                        }
                      />
                    </Box>
                  </Box>
                </Box>

                <Box
                  sx={{
                    // height: 150,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Button
                    size="large"
                    type="button"
                    variant="contained"
                    disabled={isDemoPlan}
                    sx={{
                      bgcolor: saveEnable ? "primary.light" : "info.button",
                      color: saveEnable ? "common.white" : "common.black",
                      ...btnStyle,
                      backgroundColor: palette.secondary.main,
                    }}
                    onClick={handleUpdateEmailPreferences}
                  >
                    Update
                  </Button>
                </Box>
              </Box>
            </Box>
          </Box>

          <Snackbar
            open={showConfigUpdated}
            anchorOrigin={{vertical: "top", horizontal: "right"}}
            autoHideDuration={6000}
            onClose={onCloseExportInfo}
          >
            <Alert
              onClose={onCloseExportInfo}
              severity="success"
              sx={{width: "100%"}}
            >
              Configuration updated.
            </Alert>
          </Snackbar>

          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              columnGap: "100px",
              paddingBottom: "0",
              borderTop: "1px solid #c4c4c4",
            }}
          >
            <Box
              sx={{
                paddingTop: {xs: 3, md: 4},
                paddingX: {xs: 3, md: 4},
                paddingBottom: 2,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                gap: {xs: 2, md: 4},
              }}
            >
              <h3
                style={{
                  ...typography.h3,
                  textAlign: "center",
                  width: "100%",
                }}
              >
                {
                  userAllInfo?.loginFlow == 'shopify_app' ? 'Carriers' : 'Carriers and Order Managers'
                }

              </h3>

              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  gap: {xs: 3, md: 6},
                  flexDirection: {xs: "column", md: "row"},
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    flexDirection: {xs: "column", md: "row"},
                    gap: {xs: 3, md: 6},
                  }}
                >
                  {tenantConfigInfo?.carrier?.usps && (
                    <img
                      className="carrier-img"
                      src="/assets/usps.png"
                      style={{
                        cursor: "pointer",
                      }}
                      onClick={() => onChangeClick("usps")}
                    />
                  )}

                  {tenantConfigInfo?.carrier?.ups && (
                    <img
                      className="carrier-img"
                      src="/assets/ups.png"
                      style={{
                        cursor: "pointer",
                      }}
                      onClick={() => onChangeClick("ups")}
                    />
                  )}

                  {tenantConfigInfo?.carrier?.fedex && (
                    <img
                      className="carrier-img"
                      src="/assets/fedex.png"
                      style={{
                        cursor: "pointer",
                        width: "150px",
                      }}
                      onClick={() => onChangeClick("fedex")}
                    />
                  )}

                  {tenantConfigInfo?.carrier?.dhl && (
                    <img
                      className="carrier-img"
                      src="/assets/dhl.png"
                      style={{
                        cursor: "pointer",
                        width: "160px",
                      }}
                      onClick={() => onChangeClick("dhl")}
                    />
                  )}
                </Box>
                {
                  userAllInfo?.loginFlow !== 'shopify_app' && (
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        gap: 6,
                      }}
                    >
                      {tenantConfigInfo?.order_management?.shopify && (
                        <img
                          className="manage-img"
                          src="/assets/shopify.png"
                          style={{
                            cursor: "pointer",
                          }}
                          onClick={() => onChangeClick("shopify")}
                        />
                      )}

                      {tenantConfigInfo?.order_management?.magento && (
                        <img
                          className="manage-img"
                          src="/assets/magento.png"
                          style={{
                            cursor: "pointer",
                          }}
                          onClick={() => onChangeClick("magento")}
                        />
                      )}

                      {tenantConfigInfo?.order_management?.ship_station && (
                        <img
                          className="manage-img manage-img2"
                          src="/assets/shipStation.png"
                          style={{
                            cursor: "pointer",
                          }}
                          onClick={() => onChangeClick("shipstation")}
                        />
                      )}
                    </Box>
                  )
                }

              </Box>

              <Box
                sx={{
                  display: "flex",
                  flexDirection: {xs: "column", md: "row"},
                  gap: 2,
                  marginTop: {xs: 2, md: 0},
                }}
              >
                <Button
                  size="large"
                  type="button"
                  variant="contained"
                  sx={{
                    color: "common.white",
                    backgroundColor: palette.secondary.main,
                    ...btnStyle,
                    // width: { xs: 220, lg: 300 },
                    // height: { xs: 70, lg: 48 },
                    width: 220,
                  }}
                  onClick={() => handleUpdateCarrierAndOrderManager("carrier")}
                >
                  Update Carriers
                </Button>
                {
                  userAllInfo?.loginFlow !== 'shopify_app' && (
                    <Button
                      size="large"
                      type="button"
                      variant="contained"
                      sx={{
                        color: "common.white",
                        backgroundColor: palette.secondary.main,
                        ...btnStyle,
                        // width: { xs: 220, lg: 300 },
                        // height: { xs: 70, lg: 48 },
                        width: 220,
                      }}
                      onClick={() => handleUpdateCarrierAndOrderManager("manager")}
                    >
                      Update Order Managers
                    </Button>
                  )
                }

              </Box>
            </Box>
          </Box>
        </Box>
      )}

      {isConfig && (
        <Box
          sx={{
            border: "1px solid #c4c4c4",
            borderRadius: "10px",
            padding: {xs: "24px 0px", md: "50px 100px"},
            marginTop: {xs: "20px", md: "30px"},
          }}
        >
          {isConfig === "carrier" ? (
            <SigninCarrier redirect={redirect}/>
          ) : (
            <SigninManage redirect={redirect}/>
          )}

          <Box
            sx={{
              paddingX: 2,
              paddingTop: "30px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              margin: "auto",
              gap: {xs: 1, md: 2},
            }}
          >
            <LoadingButton
              // fullWidth
              size="large"
              type="submit"
              variant="contained"
              onClick={() => {
                setIsConfig(false);
                window.location.reload();
              }}
              sx={{
                fontSize: "16px !important",
                width: {xs: 180, md: 260},
                boxShadow: "none",
                backgroundColor: "grey.600",
                "&:hover": {
                  backgroundColor: "primary.lightIcon",
                },
              }}
            >
              Cancel
            </LoadingButton>

            <LoadingButton
              // fullWidth
              size="large"
              type="submit"
              variant="contained"
              disabled={isDemoPlan}
              onClick={onSaveClick}
              sx={{
                fontSize: "16px !important",
                width: {xs: 180, md: 260},
                boxShadow: "none",
                backgroundColor: saveEnable
                  ? palette.secondary.main
                  : "info.button",
                color: saveEnable ? "common.white" : "common.black",
              }}
            >
              Save
            </LoadingButton>
          </Box>
        </Box>
      )}

      {isConfigCarrierOrderManager && (
        <Box
          sx={{
            border: "1px solid #c4c4c4",
            borderRadius: "10px",
            padding: {xs: "24px 0px", md: "50px 100px"},
            marginTop: {xs: "20px", md: "30px"},
          }}
        >
          {isConfigCarrierOrderManager === "carrier" ? (
            <Carrier/>
          ) : (
            <ManageForm/>
          )}

          <Box
            sx={{
              paddingX: 2,
              paddingTop: "30px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              margin: "auto",
              gap: {xs: 1, md: 2},
            }}
          >
            <LoadingButton
              // fullWidth
              size="large"
              type="submit"
              variant="contained"
              onClick={() => {
                setIsConfigCarrierOrderManager(false);
                window.location.reload();
                // dispatchRedux(
                //   setSigninData({
                //     ...originalSigninData,
                //   })
                // );
              }}
              sx={{
                fontSize: "16px !important",
                width: {xs: 180, md: 260},
                boxShadow: "none",
                backgroundColor: "grey.600",
                "&:hover": {
                  backgroundColor: "primary.lightIcon",
                },
              }}
            >
              Cancel
            </LoadingButton>

            <LoadingButton
              // fullWidth
              size="large"
              type="submit"
              variant="contained"
              disabled={
                signinData?.carrierError ||
                signinData?.manageError ||
                !signinData?.carrier ||
                signinData?.carrier == null ||
                !signinData?.manager ||
                signinData?.manager == null ||
                signinData?.manager == ""
              }
              onClick={() => {
                setIsConfig(isConfigCarrierOrderManager);
                setIsConfigCarrierOrderManager(false);
              }}
              sx={{
                fontSize: "16px !important",
                width: {xs: 180, md: 260},
                boxShadow: "none",
                backgroundColor: saveEnable
                  ? palette.secondary.main
                  : "info.button",
                color: saveEnable ? "common.white" : "common.black",
              }}
            >
              Continue
            </LoadingButton>
          </Box>
        </Box>
      )}

      <ModalComponent
        openModal={showConfirmation}
        handleCloseModal={handleCloseModal}
        handleBtnClick={handleCloseModal}
        title={"Configuration"}
        description={`${
          isLogoUpdate
            ? "Logo"
            : isEmailPreferencesUpdate
              ? "Email preferences"
              : "Information "
        } 
      updated succesfully.`}
        btnText={"Continue"}
      />
    </Box>
  );
}
