import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Box, Typography } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { storeCredentialsInCookies } from "src/utils/cookiesTracker";
import { useSelector } from "react-redux";
import { login } from "src/features/user/userState";
import { useAppDispatch } from "src/hooks/hooks";
import { getToken } from "src/utils/extractUserToken";

import palette from "src/theme/palette";
import CircularLoader from "src/loader/circular/CircularLoader";

import "./welcome.scss";
import appUrls from "src/utils/appUrls";

const pStyle = {
  maxWidth: "800px",
  color: palette.common.black,
  fontSize: 18,
  textAlign: "center",
};

const WelcomeView = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [fetchedToken, setFetchedToken] = useState(null);
  const dispatch = useAppDispatch();
  const signinData = useSelector((state) => state.signin.signinData);
  const handleOnClick = () => {
    setIsLoading(true);
    const credentials = {
      email: signinData?.email,
      password: signinData?.password,
      accessToken:signinData?.accessToken,
      loginFlow: signinData?.loginFlow
    };
    dispatch(login(credentials))
      .then((action) => {
        const { email, password } = action.meta.arg;
        storeCredentialsInCookies(email, password);
        setFetchedToken(getToken());
        setIsLoading(false);
      })
      .catch(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    if (fetchedToken) {
      navigate(appUrls.outboundManagement.dashboard.absoluteUrl);
    }
  }, [fetchedToken]);

  return (
    <>
      {isLoading && <CircularLoader />}

      <div className="welcome-view-container">
        <div style={{ padding: "20px 0px 0px 20px" }}>
          <img
            src="/assets/beyond-carts-logo.svg"
            alt="logo"
            style={{
              height: "48px",
              display: "block",
            }}
          />
        </div>

        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            gap: 3,
            height: "100%",
          }}
        >
          <Typography variant="h3" sx={{ textAlign: "center" }}>
            Welcome to Beyond Carts!
          </Typography>

          <Typography sx={pStyle}>
            A big welcome to you from the Beyond Carts family. At Beyond Carts,
            we empower you with hassle-free tracking of your shipments. Dive
            into a world where logistics is simplified, and every delivery is
            just a click away. We're here to support you every step of the way
            in your shipment tracking needs. Let's get those packages moving!
          </Typography>

          <Box textAlign="center">
            <LoadingButton
              fullWidth
              size="large"
              type="submit"
              variant="contained"
              onClick={handleOnClick}
              sx={{
                fontSize: 16,
                width: { xs: 180, lg: 350 },
                boxShadow: "none",
                backgroundColor: palette.secondary.main,
              }}
            >
              Get Started
            </LoadingButton>
          </Box>
        </Box>
      </div>
    </>
  );
};

export default WelcomeView;
